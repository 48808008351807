import * as PIXI from 'pixi.js';
import SpriteCommon from '../common/SpriteCommon';
import ResourceList from '../../../services/ResourceList';
import { AppConfig } from '../../../config/AppConfig';
import { Utils } from '../../utils/Utils';
import Background3D from './Background3D';

class BackgroundAbstract extends PIXI.Container {
    constructor() {
        super();
        this.createElements();
        this.onResize();
        this.showHideLights(false);

        this.cameraUpdated = () => {
            this.onResize();
        }
        AppConfig.cameraUpdated.add(this.cameraUpdated);
    }

    /**
     * @access public
     */
    createElements() {
        if (this.wall && this.floor) {
            this.addChild(this.wall);
            this.addChild(this.floor);
    
            this.door.anchor.set(0.5, 1);
            this.addChild(this.door);
    
            this.conveyor = new SpriteCommon(ResourceList.CONVEYOR);
            // this.conveyor = new PIXI.NineSlicePlane(ResourceService.getTexture(ResourceList.CONVEYOR), 255, 255, 0, 0);
            //TODO Make NineSlice for conveyor for avoiding mismatching shelves and conveyor lines

            
            this.conveyor.anchor.set(0.5, 0);
            this.conveyor.height = 780;
            this.addChild(this.conveyor);
    
            this.banner.anchor.set(0.5, 0.5);
            this.addChild(this.banner);
        }

        this.conveyorLightLeft = new SpriteCommon(ResourceList.CONVEYOR_LIGHT_LEFT);
        this.conveyorLightRight = new SpriteCommon(ResourceList.CONVEYOR_LIGHT_LEFT);
        this.conveyorLightLeft.anchor.set(0, 0);
        this.conveyorLightRight.anchor.set(0, 0);
        this.conveyorLightRight.scale.set(-1, 1);
        this.addChild(this.conveyorLightLeft);
        this.addChild(this.conveyorLightRight);
        this.conveyorLightLeft.blendMode = PIXI.BLEND_MODES.SCREEN;
        this.conveyorLightRight.blendMode = PIXI.BLEND_MODES.SCREEN;
    }

    createElementsAfter() {
        if (AppConfig.settings3D.showConeyor3Dhelper){
            this.bg3D = new Background3D();
            this.addChild(this.bg3D);   
        }
    }


    /**
     * @access public
     */
    onResize() {
        const { DOOR_MAX_SCALE, DOOR_MIN_SCALE } = AppConfig.base;
        const { gameWidth, gameHeight } = AppConfig.settings;
        const { floorLineFrom3DCenter } = AppConfig.settings3D;
        const centerX = gameWidth / 2;
        const { horyzontPos } = AppConfig.settings3D;
        const centerY = gameHeight * horyzontPos;
        const floorLine = centerY + floorLineFrom3DCenter;;
        
        this.wall.y = 0;
        this.wall.x = 0;
        this.wall.onResize(gameWidth, floorLine, "center", "bottom");
        this.floor.onResize(gameWidth, gameHeight, "center", "top");
        this.floor.y = floorLine;
        this.door.x = centerX;
        
        this.door.height = centerY - 10;
        let doorScale = this.door.scale.y;
        if (doorScale < DOOR_MIN_SCALE) doorScale = DOOR_MIN_SCALE;
        if (doorScale > DOOR_MAX_SCALE) doorScale = DOOR_MAX_SCALE;
        this.door.scale.set(doorScale, doorScale);
        this.door.y = floorLine;


        this.banner.x = centerX;
        this.banner.y = floorLine - this.door.height;

        this.conveyor.alpha = 1;
        this.conveyor.x = centerX;
        this.conveyor.y = this.door.y - this.door.height * 0.4;

        // this.conveyor.scale.set(1);
        const doorPhase = Utils.inverseLerp(this.door.scale.x, DOOR_MIN_SCALE, DOOR_MAX_SCALE);
        // this.conveyor.width = Utils.lerp(doorPhase, 600, 890);


        AppConfig.dynamicHelpers.doorBottom = this.door.y;
        AppConfig.dynamicHelpers.doorTop = this.door.y - this.door.height;
        AppConfig.dynamicHelpers.doorPhase = doorPhase;

        const conveyorLineY = gameHeight - this.conveyor.y;
        const conveyorLineXFromY = (conveyorLineY * (238 / 780) + 88) * this.conveyor.scale.x;
        // const conveyorLineXFromY = (conveyorLineY * (238 / 607));

        AppConfig.dynamicHelpers.conveyorBottomCorner = conveyorLineXFromY;
        // console.log(conveyorLineXFromY);

        this.conveyorLightLeft.y = this.conveyor.y;
        const displaceX = 260;
        this.conveyorLightLeft.x =  Utils.lerp(doorPhase, centerX - displaceX, centerX - displaceX - 45);
        this.conveyorLightRight.y = this.conveyor.y;
        this.conveyorLightRight.x = Utils.lerp(doorPhase, centerX + displaceX, centerX +  displaceX + 45);
        
        const objSkew = Utils.lerp(doorPhase, 0.05, -0.08);
        this.conveyorLightLeft.skew.x = objSkew;
        this.conveyorLightRight.skew.x = -objSkew;
/*
        if (this.banner) {
            this.banner.x = gameWidth / 2;
            this.banner.y = 20 * this.door.scale.y;
        } */ 
         if (AppConfig.settings3D.showConeyor3Dhelper) this.bg3D.redraw();
    }

    /**
     * @access public
     */
    showHideLights(show) {
        this.conveyorLightLeft.visible = show ? true : false;
        this.conveyorLightRight.visible = show ? true : false;
        
    }

}

export default BackgroundAbstract;