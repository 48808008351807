import GameModel, { EGameStates } from "../../model/GameModel";
import AnalyticsEvents from "../../services/AnalyticsEvents";
import AnalyticsService from "../../services/AnalyticsService";
import PopupAbstract from "./PopupAbstract";

class FinishPopUp extends PopupAbstract{
    /**
     * 
     * @param {GameModel} gameModel 
     */
    constructor(gameModel) {
        super(gameModel);
        this.setLinks('finish-game-');

        if (this.domLink) {
            this.playAgainBtnDOM.cursor = "pointer";

            this.playAgainBtnDOM.addEventListener('pointerup', () => {
                this.gameModel.registerPlayAgainInteraction();
                this.visible = false;
                AnalyticsService.logEvent(AnalyticsEvents.LOSECREEN_PLAY_AGAIN);

            });
        }

        this.gameModel.gameStateUpdated.add(() => {
            if (this.gameModel.gameState === EGameStates.stop){
                if (!this.gameModel.scoresAchived) {
                    // this.visible = true;
                    this.visible = false;
                } 
                // this.visible = true;
            } else if (this.gameModel.gameState === EGameStates.playing){
                this.visible = false;

            }
            
        });
    }
    
    setLinks(domID) {
        super.setLinks(domID);
    }

    /**
     * @type {boolean}
     */
    set visible(value) {
        super.visible = value;
        if (value) {
            AnalyticsService.logEvent(AnalyticsEvents.LOSE_SCREEN);
        } 

    }
    


 

}

export default FinishPopUp