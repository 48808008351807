import EItemsID from "../model/EItemsID";

class SoundRecord {
    constructor(src, volume, loop = false) {
        this.src = src;
        this.volume = volume;
        this.loop = loop;
    }
}

class SoundData {
    static MOVE = "move";
    static MATCH = "match";
    static PAINT = "paint";

    static CATCH_PLUS10 = "catch" + EItemsID.PLUS10;
    static CATCH_PLUS20 = "catch" + EItemsID.PLUS20;
    static CATCH_MINUS_N_POINTS = "catch" + EItemsID.MINUS_N_POINTS;
    static CATCH_MINUS_N_SECONDS = "catch" + EItemsID.MINUS_N_SECONDS;
    static CATCH_PLUS_N_SECONDS = "catch" + EItemsID.PLUS_N_SECONDS;
    static CATCH_MAGNET = "catch" + EItemsID.MAGNET;
    static CATCH_SPEED_UP = "catch" + EItemsID.SPEED_UP;

    static GET_SCORE_MULTIPLAYER = "getScoreMultiplayer";

    static LOOP_BG_MUSIC = "loopBgMusic";
    static LOOP_MAGNET = "loopMagnet";
    static LOOP_SPEEDUPT = "loopSpeedUp";

    static MSC_COUNDDOWN = "countDown";
    static MSC_COUNDDOWN_GO = "countDownGo";
    static MSC_START = "start";
    static MSC_FINISH = "finish";



    static data = {
        [SoundData.CATCH_PLUS10]: new SoundRecord("./assets/sounds/plus10.wav", 0.5,),
        [SoundData.CATCH_PLUS20]: new SoundRecord("./assets/sounds/plus20.mp3", 0.5),
        [SoundData.CATCH_MINUS_N_POINTS]: new SoundRecord("./assets/sounds/waterSplash.mp3", 0.5),
        [SoundData.CATCH_MINUS_N_SECONDS]: new SoundRecord("./assets/sounds/test3.wav", 0.5),
        [SoundData.CATCH_PLUS_N_SECONDS]: new SoundRecord("./assets/sounds/plusNseconds.mp3", 0.8),
        [SoundData.CATCH_MAGNET]: new SoundRecord("./assets/sounds/pick-good.mp3", 0.5),
        [SoundData.CATCH_SPEED_UP]: new SoundRecord("./assets/sounds/pick-good.mp3", 0.5),
        [SoundData.MOVE]: new SoundRecord("./assets/sounds/move.wav", 0.5),
        [SoundData.MATCH]: new SoundRecord("./assets/sounds/match.wav", 0.4),
        [SoundData.PAINT]: new SoundRecord("./assets/sounds/paint.wav", 0.5),

        [SoundData.GET_SCORE_MULTIPLAYER]: new SoundRecord("./assets/sounds/speed-multiplayer.mp3", 0.5),
        [SoundData.LOOP_BG_MUSIC]: new SoundRecord("./assets/sounds/bg-music.mp3", 0.2, true),
        [SoundData.LOOP_MAGNET]: new SoundRecord("./assets/sounds/magnet-loop.mp3", 0.4, true),
        [SoundData.LOOP_SPEEDUPT]: new SoundRecord("./assets/sounds/spped-up-loop.mp3", 0.4, true),
        
        [SoundData.MSC_COUNDDOWN]: new SoundRecord("./assets/sounds/count.mp3", 1,),
        [SoundData.MSC_COUNDDOWN_GO]: new SoundRecord("./assets/sounds/go1.mp3", 0.4,),
        [SoundData.MSC_START]: new SoundRecord("./assets/sounds/start2.mp3", 0.5,),
        [SoundData.MSC_FINISH]: new SoundRecord("./assets/sounds/finish.mp3", 0.5,)
    };
}

export default SoundData