import * as PIXI from 'pixi.js';
import ResourceService from '../../../services/ResourceService';

class FixiedTilingSprite extends PIXI.TilingSprite {
    constructor(resourceName, textureWidth, textureHeight, inCartPoint = 0) {
        const texture = ResourceService.getTexture(resourceName);
        super(
            texture,
            textureWidth,
            textureHeight,
        );
        this.textureWidth = textureWidth;
        this.textureHeight = textureHeight;

        this.resourceName = resourceName;
        this.inCartPoint = inCartPoint;
    }

    onResize(width, height, hAlign = 'center', vAlign = `center`) {
        this.width = width
        this.height = height;
        const scX = this.textureWidth / width;
        const scY = this.textureHeigh / height;
        if (hAlign === 'center') {
            this.tilePosition.x = (width % this.textureWidth) / 2;
        } else if (hAlign === 'left')
        {
            this.tilePosition.x = (width - this.textureWidth);
        }
        if (vAlign === 'center') {
            this.tilePosition.y = (height % this.textureHeight) / 2;
        } else if (vAlign === 'bottom')
        {
            // this.tilePosition.y = (width - this.textureWidth);
            // this.tilePosition.y = height % this.textureHeight;
            this.tilePosition.y = (height % this.textureHeight);
        } else if (vAlign === 'top') {
            
        }

    }


}

export default FixiedTilingSprite;