import * as PIXI from 'pixi.js';
import { AnimatedSprite } from 'pixi.js';
import { gsap } from 'gsap';
import StartButton from './StartButton';
import { AppConfig } from '../../config/AppConfig';
import SpriteCommon from './common/SpriteCommon';
import ResourceList from '../../services/ResourceList';
import GameModel from '../../model/GameModel';
import EMessages from '../../services/EMessages';
import { SoundManager } from '../../services/SoundManager';
import GameScreen from '../screens/GameScreen';
import SoundData from '../../services/SoundData';
import Utils3D from '../utils/Utils3D';
import { Utils } from '../utils/Utils';
import Language from '../../config/Language';
import AnalyticsService from '../../services/AnalyticsService';
import AnalyticsEvents from '../../services/AnalyticsEvents';

class Countdown extends PIXI.Container {
    /**
     * 
     * @param {GameModel} gameModel 
     * @param {GameScreen} gameScreen 
     */
    constructor(gameModel, gameScreen) {
        super();
        const { gameHeight } = AppConfig.settings;
        this.gameModel = gameModel;
        this.gameScreen = gameScreen;
        this.bg = new PIXI.Graphics();
        this.bg.eventMode = "passive";
        this.bg.alpha = 1;
        this.showTrainer = true;
        this.showTrainer =  this.gameModel.communictionService.shouldShowTutorial;
        this.gameModel.communictionService.tutorialStatusUpdated.add((shouldShow) => {
            this.showTrainer = shouldShow;
        });

        this.pointerGuide = new TreinerPointer();
        this.pointerGuide.visible = false;
        this.pointerGuide.eventMode = "passive";
        this.trainerIntervalID = -1;

        this.trainPassed = {left: false, center: false, righ:false};
        this.trainCurrentPos = -1;
        this.onCartLineUpdated = () => {
            if (this.showTrainer){
                this.checkClicked(this.trainCurrentPos);
            }
            
        };

        this.bgALpha = 0.4;

        this.setTrainerByPos = (pos) =>{
            const { gameWidth, gameHeight } = AppConfig.settings;
            this.pointerGuide.iconPos = pos;

            const trgX = Utils3D.getCartXPosByPosInRow(pos) - gameWidth /2;
            const trgY =  gameHeight / 2 - 60; 
            this.pointerGuide.phase = 0;
    
            gsap.timeline()
            .to(this.pointerGuide, { 
                x: trgX, 
                y: trgY, duration: 0.6, 
                ease: "power.out", 
                delay:1,
                onComplete: () => {
                    this.checkClicked(this.trainCurrentPos);
                }})
            .to(this.pointerGuide, { phase: Math.PI * 8, duration: 2, ease: "none" })
    
        }
        this.gameModel.cartLineUpdated.add(this.onCartLineUpdated);

        this.onResize = () => {

            const { gameWidth, gameHeight } = AppConfig.settings;
             const CART_VPOS = 0.7;

            this.redrawBG();
            this.reglesButton.y = gameHeight / 2 - 50;
            this.startButton.y = gameHeight / 2 - 150;
            
            const splashDefault = 1440;
            const splashMinWidth = 759;
            const splashMinHeight = 992;
            this.splash.width = splashDefault;
            const bottonTopPosLocal = this.startButton.y - 30;
            //we use this poitnas bottom scale axis
            let splashSC = 1;
            if (gameWidth < splashMinWidth) {
                splashSC = gameWidth / splashMinWidth;
                const splashMinHeightResult = splashMinHeight * splashSC;
                if (gameHeight < splashMinHeightResult) {
                    splashSC = gameHeight / splashMinHeight;
                }
                
            }
            if (gameHeight < splashMinHeight) {
                splashSC = gameHeight / splashMinHeight;
                const splashMinWidthResult = splashMinWidth * splashSC;
                if (gameWidth < splashMinWidthResult) {
                    splashSC = gameWidth / splashMinWidth;
                }
            }

            this.splash.scale.set(splashSC, splashSC);   
            this.splash.x = 0;

 


            let splashDisplaceY = this.splash.height;
            if (splashDisplaceY > 200) splashDisplaceY = 200;
            //splashDisplceY = 0;
            this.splash.y = gameHeight / 2  - this.splash.height;
            const textPostionLocal = this.splash.y + this.splash.height * CART_VPOS;
            
            if (textPostionLocal < bottonTopPosLocal) {
                this.splash.y = bottonTopPosLocal - this.splash.height * CART_VPOS;
            }
            // let max = 

            this.splashLeft.y = this.splashRight.y = this.splash.y;

            if (gameWidth > this.splash.width) {
                this.splashLeft.scale.set(1, splashSC);
                this.splashRight.scale.set(1, splashSC);
                this.splashLeft.y = this.splash.y;
                this.splashRight.y = this.splash.y;
                this.splashLeft.width = (gameWidth - this.splash.width) / 2;
                this.splashRight.width = this.splashLeft.width;
                this.splashLeft.x = - gameWidth / 2;
                this.splashRight.x = gameWidth / 2;
                this.splashRight.visible = true;
                this.splashLeft.visible = true;                   
            } else {
                this.splashRight.visible = false;
                this.splashLeft.visible = false;
            }

            const minFreeSpaceForLogoAndBox = gameWidth / 2 - 160;
      
            if (minFreeSpaceForLogoAndBox < 201) {
                this.splashLogo.width = minFreeSpaceForLogoAndBox > 80 ? minFreeSpaceForLogoAndBox : 80; 
            } else {
                this.splashLogo.width = 201;
            }
            this.splashLogo.scale.y = this.splashLogo.scale.x;

            if (minFreeSpaceForLogoAndBox < 210) {
                this.splashBox.width = minFreeSpaceForLogoAndBox > 80 ? minFreeSpaceForLogoAndBox : 80; 
            } else {
                this.splashBox.width = 210;
            }
            this.splashBox.scale.y = this.splashBox.scale.x;
            if (gameHeight < 800) {
                const additionalBoxScale = 1 - 0.8 * ((800 - gameHeight) / 800);
                const finalBoxScale = this.splashLogo.scale.x * additionalBoxScale;
                this.splashBox.scale.set(finalBoxScale, finalBoxScale);
            }

            this.splashLogo.x = gameWidth / 2 - 20;
            this.splashLogo.y = gameHeight / 2 - 20;


            this.splashBox.x = - gameWidth / 2 - 10;
            if (this.splashBox.x > -600) this.splashBox.x = - 600;
            

            this.splashBox.y = gameHeight / 2 + 60;
        }
        AppConfig.sizeUpdated.add(this.onResize);
        this.addChild(this.bg);

        this.splash = new SpriteCommon(ResourceList["SPLASH_" + AppConfig.lang]);
        this.splash.eventMode = "none";
        this.splash.scale.set(1, 1);
        this.splash.anchor.set(0.5, 0);


        this.splashLeft = new SpriteCommon(ResourceList.SPLASH_LEFT);
        this.splashLeft.scale.set(1, 1);
        this.splashLeft.anchor.set(0, 0);

        this.splashRight = new SpriteCommon(ResourceList.SPLASH_RIGHT);
        this.splashRight.scale.set(0, 1); 
        this.splashRight.anchor.set(1, 0);

        this.splashBottom = new PIXI.Graphics ();

        this.splashBox = new SpriteCommon(ResourceList.SPLASH_BOX);
        this.splashBox.anchor.set(0, 1);

        this.splashLogo = new SpriteCommon(ResourceList["SPLASH_LOGO_" + AppConfig.lang]);
        this.splashLogo.anchor.set(1, 1);  
        // this.splashLogo.visible = false;

        this.splashCont = new PIXI.Container();
        this.addChild(this.splashCont);

        this.splashCont.addChild(this.splashBottom);
        // this.splashCont.addChild(this.splashLeft);
        // this.splashCont.addChild(this.splashRight); 
        this.splashCont.addChild(this.splash);
        // this.splashCont.addChild(this.splashBox);
        // this.splashCont.addChild(this.splashLogo);
        
        const lang = AppConfig.lang;
        this.text4 = new SpriteCommon(ResourceList["COUNTDOWN_4_" + lang]);
        this.text3 = new SpriteCommon(ResourceList["COUNTDOWN_3_" + lang]);
        this.text2 = new SpriteCommon(ResourceList["COUNTDOWN_2_" + lang]);
        this.text1 = new SpriteCommon(ResourceList["COUNTDOWN_1_" + lang]);
        this.text0 = new SpriteCommon(ResourceList["COUNTDOWN_GO_" + lang]);

        this.startButton = new StartButton();
        this.startButton.y = gameHeight / 2 - 150;
        this.reglesButton = new SpriteCommon(ResourceList["MSC_BTN_REGLES_" + lang]);
        this.addChild(this.startButton);
        this.addChild(this.reglesButton);
        this.reglesButton.anchor.set(0.5, 0.5);
        this.reglesButton.eventMode = "dynamic";

        this.reglesButton.on('pointerdown', ()=> {
            this.gameModel.showRules = true;
            AnalyticsService.logEvent(AnalyticsEvents.RULES_CLICK);
        });
        
        this.startButton.on('startButtonClick', () => {
            this.emit('countdownStarted');
            gsap.to(this.bg, { alpha: this.bgALpha, duration: 0.6});
            this.text4.alpha = 0;
            const duration = AppConfig.gameSettings.counDownStepDuration / 2;
            gsap.timeline()
            .to(this.startButton, { alpha: 0, duration: duration, onStart: () => this.text4.visible = true })
            .call(() => {
                if (this.showTrainer) {
                    this.startTrainer();
                } else {
                    this.startCountdown();
                }
                this.startButton.visible = false;
                this.splashCont.visible = false;               
            }); 

            gsap.to(this.splashCont, { alpha: 0, duration: duration});
            //gsap.to(this.bg, { alpha: this.bgALpha, duration: duration});

          });

        this.addChild(this.text4, this.text3, this.text2, this.text1, this.text0);
        this.addChild(this.pointerGuide);

        this.text4.visible = false;
        this.text3.visible = false;
        this.text2.visible = false;
        this.text1.visible = false;
        this.text0.visible = false;

        this.text4.anchor.set(0.5);
        this.text3.anchor.set(0.5);
        this.text2.anchor.set(0.5);
        this.text1.anchor.set(0.5);
        this.text0.anchor.set(0.5);

        this.text4.position.set(0, 0);
        this.text3.position.set(0, 0);
        this.text2.position.set(0, 0);
        this.text1.position.set(0, 0);
        this.text0.position.set(0, 0);

        this.onResize();

  /*       this.spritesheet = ResourceService.getSpriteSheet(ResourceList.EFFECTS);
        this.anim = new AnimatedSprite(this.spritesheet.animations['lightning']);
        this.anim.play();
        this.addChild(this.anim); */
    }

    startTrainer() {

        this.pointerGuide.visible = true;
        this.bg.eventMode = "passive";
        this.pointerGuide.alpha = 0;

        gsap.to(this.splashCont, { alpha: 1, duration: 0.6, onComplete:()=> {
            this.setTrainerByPos(-1);
        }});
        gsap.to(this.bg, { alpha: this.bgALpha, duration: 0.6});
        gsap.to(this.reglesButton, { alpha: 0, duration: 0.6, delay: 0, 
            onComplete:()=>{
            this.reglesButton.visible = false;
        }});
    }

    checkClicked(pos) {
        if (this.showTrainer == false) return
        if (this.gameModel.cartLine === pos) {
            if (pos === 1) {
                this.pointerGuide.visible = false;
                this.startCountdown();
                this.sendTutorialPassed();
            } else {
                this.trainCurrentPos ++;
                this.setTrainerByPos(this.trainCurrentPos);
            }
        }

        if (this.trainerIntervalID > 0) clearTimeout(this.trainerIntervalID);
        // this.trainerIntervalID = setTimeout(this.setTrainerByPos, 5000, this.trainCurrentPos);
    }

    sendTutorialPassed() {
        this.gameModel.sendMessage(EMessages.SND_TUTORIAL_END);
    }
    /**
     * @access public
     */
    resetCountDown() {
        this.visible = true;
        this.startButton.visible = true;
        this.startButton.alpha = 1;
        this.splashCont.alpha = 1;
        this.visible = true;
        this.splashCont.visible = true;
        this.reglesButton.visible = true;
        this.reglesButton.alpha = 1;
        this.bg.visible = true;
        gsap.to(this.bg, { alpha: 1, duration: 0.6});

    }

    startCountdown() {
        const { counDownStepDuration } = AppConfig.gameSettings;
        const duration = counDownStepDuration;
        this.trainCurrentPos = -1;
        this.showTrainer = false;
        this.pointerGuide.visible = false;
        clearInterval(this.trainerIntervalID);
        gsap.to(this.reglesButton, { alpha: 0, duration: duration, delay: 0, 
            onComplete:()=>{
            this.reglesButton.visible = false;
            this.gameScreen.soundManager.play(SoundData.MSC_COUNDDOWN);
        }});
        gsap.timeline()
          .to(this.text4, { alpha: 1, duration: duration, delay: duration })
          .to(this.text4, { alpha: 0, duration: duration, delay: duration * 6 })
          .to(this.text3, { alpha: 1, duration: duration,
            onStart: () => {
                this.text3.visible = true;
                this.gameScreen.soundManager.play(SoundData.MSC_COUNDDOWN);
            }})
          .to(this.text3, { alpha: 1, duration: duration, delay: duration })
          .to(this.text3, { alpha: 0, duration: duration, delay: duration })
          .to(this.text2, { alpha: 1, duration: duration, 
            onStart: () => {
                this.text2.visible = true;
                this.gameScreen.soundManager.play(SoundData.MSC_COUNDDOWN);
            }})
          .to(this.text2, { alpha: 0, duration: duration, delay: duration })
          .to(this.text1, { alpha: 1, duration: duration, 
            onStart: () => {
                this.text1.visible = true;
                this.gameScreen.soundManager.play(SoundData.MSC_COUNDDOWN);
            }})
          .to(this.text1, { alpha: 0, duration: duration, delay: duration })
          .to(this.text0, { alpha: 1, duration: duration, 
            onStart: () => {
                this.text0.visible = true;
                this.gameScreen.soundManager.play(SoundData.MSC_COUNDDOWN_GO);
            }})
          .to(this.text0, { alpha: 0, duration: duration, delay: duration })
          .call(() => {
            this.emit('countdownComplete');
            this.text4.visible = false;
            this.text3.visible = false;
            this.text2.visible = false;
            this.text1.visible = false;
            this.text0.visible = false;
            this.visible = false;
            this.gameScreen.soundManager.play(SoundData.MSC_START);
        }); 
    }

    redrawBG(){
        const { gameWidth, gameHeight } = AppConfig.settings;
        this.bg.clear();
        this.bg.beginFill(0x100a23);
        this.bg.drawRect( - gameWidth / 2, - gameHeight / 2,  gameWidth, gameHeight);
        this.bg.endFill();
    }   
}

class TreinerPointer extends PIXI.Sprite {
    constructor() {
        const { gameWidth, gameHeight } = AppConfig.settings;
        super(PIXI.Texture.EMPTY);
        this.mouseIconLeft = new SpriteCommon(ResourceList["MSC_CART_CLICKER_LEFT_" + AppConfig.lang]);
        this.mouseIconCenter = new SpriteCommon(ResourceList["MSC_CART_CLICKER_CENTER_" + AppConfig.lang]);
        this.mouseIconRight = new SpriteCommon(ResourceList["MSC_CART_CLICKER_RIGHT_" + AppConfig.lang]);
        this.addChild(this.mouseIconLeft);
        this.addChild(this.mouseIconCenter);
        this.addChild(this.mouseIconRight);
        // this.mouseIconLeft.alpha = 1;
        // this.mouseIconCenter.alpha = 0;
        // this.mouseIconRight.alpha = 0; 

        this.mouseIconLeft.eventMode = "passive";
        this.mouseIconCenter.eventMode = "passive";
        this.mouseIconRight.eventMode = "passive";

        this.mouseIconLeft.anchor.set(0.5, 0.5);
        this.mouseIconCenter.anchor.set(0.5, 0.5);
        this.mouseIconRight.anchor.set(0.5, 0.5);

        this._iconPos = 0;
        this.iconPos = -1;

        this.eventMode = "passive";
        this._phase = Math.PI / 2;
        this.anchor.set(0.5, 0.5);
        this.y = 100;       
    }

    get phase() {return this._phase};
    set phase (value) {
        if (this._phase === value) return
        this._phase = value;
        this.alpha = Math.abs(Math.cos(value))
    }

    get iconPos() {return this._iconPos};
    set iconPos (value) {
        if (this._iconPos === value) return
        this._iconPos = value;
        const duration = 0.7;
        const delay = 0.75;
        if (value === -1) {
            
            this.mouseIconLeft.alpha = 0;
            gsap.to(this.mouseIconLeft, { alpha: 1, duration: duration, delay: delay });
            gsap.to(this.mouseIconCenter, { alpha: 0, duration: duration, delay: delay });
            gsap.to(this.mouseIconRight, { alpha: 0, duration: duration, delay: delay });
        }
        if (value === 0) {
            this.mouseIconCenter.alpha = 0;
            gsap.to(this.mouseIconLeft, { alpha: 0, duration: duration, delay: delay});
            gsap.to(this.mouseIconCenter, { alpha: 1, duration: duration, delay: delay});
            gsap.to(this.mouseIconRight, { alpha: 0, duration: duration, delay: delay});
        }
        if (value === 1) {
            this.mouseIconRight.alpha = 0;
            gsap.to(this.mouseIconLeft, { alpha: 0, duration: duration, delay: delay});
            gsap.to(this.mouseIconCenter, { alpha: 0, duration: duration, delay: delay});
            gsap.to(this.mouseIconRight, { alpha: 1, duration: duration, delay: delay});
        }
    } 


}




export default Countdown