class AnalyticsEvents {}

AnalyticsEvents.LOADING_SCREEN = "view_home";
AnalyticsEvents.MAIN_GAME = "view_game";
AnalyticsEvents.RULES_VIEW = "view_rules";
AnalyticsEvents.WIN_SCREEN = "view_win";
AnalyticsEvents.LOSE_SCREEN = "view_lose";
AnalyticsEvents.POPUP_REACH1000 = "view_reached";

AnalyticsEvents.START_GAME = "click_home_start_game";
AnalyticsEvents.RULES_CLICK = "click_rules";
AnalyticsEvents.SOUND = "click_game_icon_sound";
AnalyticsEvents.WINSCREEN_COPY_CODE = "click_copy_code";
AnalyticsEvents.WINSCREEN_PLAY_AGAIN = "click_win_play_again";
AnalyticsEvents.LOSECREEN_PLAY_AGAIN = "click_lose_play_again";
AnalyticsEvents.POPUP_1000POINTS_CLAIM_PRIZE = "click_popup_claim_entry";
AnalyticsEvents.POPUP_1000POINTS_KEEP_PLAYING = "click_popup_keep_playing";


export default AnalyticsEvents; 