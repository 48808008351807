import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent } from "firebase/analytics";
import { getAuth, browserSessionPersistence } from "firebase/auth";
class AnalyticsService {

    static init() {
        const firebaseConfig = {
            apiKey: "AIzaSyCE0wFa5E-hMzc2zVy7Mt9DSXhbhaBwspQ",
            authDomain: "getir-mini-games.firebaseapp.com",
            projectId: "getir-mini-games",
            storageBucket: "getir-mini-games.appspot.com",
            messagingSenderId: "650644018303",
            appId: "1:650644018303:web:ecdfee803cf3eb8ed99b4a",
            measurementId: "G-0YWPP2VHX8"
          };
        AnalyticsService.app = initializeApp(firebaseConfig);
        AnalyticsService.analytics = getAnalytics(AnalyticsService.app);
        
    }


    /**
     * 
     * @param {string} tag 
     */
    static logEvent(tag) {
        logEvent(AnalyticsService.analytics, tag);
        console.log("-> Firebase : " + tag );

    }
}

export default AnalyticsService