import * as PIXI from 'pixi.js';
import { AppConfig } from "../../../config/AppConfig";
import ResourceList from "../../../services/ResourceList";
import BackgroundAbstract from "./BackgroundAbstract";
import FixiedTilingSprite from './FixiedTilingSprite';
import SpriteCommon from '../common/SpriteCommon';
import Background3D from './Background3D';
import { Utils } from '../../utils/Utils';

class BackgroundG2 extends BackgroundAbstract{
    constructor() {
        super();
    }

     /**
     * @access public
     */
     createElements() {
        
        this.wall = new FixiedTilingSprite(ResourceList.BG_2_WALL, 431, 383);
        this.floor = new FixiedTilingSprite(ResourceList.BG_2_FLOOR, 800, 600);
        this.door = new SpriteCommon(ResourceList.BG_2_DOOR);
        this.banner = new SpriteCommon(ResourceList.MSC_2_BANNER);
        this.banner.visible = false;

        super.createElements();

        const { gameWidth, gameHeight } = AppConfig.settings;
        

        this.objectLeft1 = new SpriteCommon(ResourceList.BG_2_OBJ_LEFT_1);
        this.objectRight1 = new SpriteCommon(ResourceList.BG_2_OBJ_RIGHT_1);

        this.objectLeft1.anchor.set(1, 0);
        this.objectRight1.anchor.set(0, 0);

        this.addChild(this.objectLeft1);
        this.addChild(this.objectRight1);


        this.createElementsAfter();

    }


    /**
     * @access public
     */
    onResize() {
        super.onResize();
        const { DOOR_MAX_SCALE, DOOR_MIN_SCALE } = AppConfig.base;
        const { gameWidth, gameHeight } = AppConfig.settings;
        const { horyzontPos } = AppConfig.settings3D;
        const { floorLineFrom3DCenter } = AppConfig.settings3D;
        const centerY = gameHeight * horyzontPos;
        const floorLine = centerY + floorLineFrom3DCenter;;
        
        const centerX = gameWidth / 2;
        

        const conveyorWidth = 330 * this.door.scale.x;

        const doorPhase = Utils.inverseLerp(this.door.scale.x, DOOR_MIN_SCALE, DOOR_MAX_SCALE);
        // const objDisplace = Utils.lerp(doorPhase, 17, -50);
        const objDisplace = 16;
        this.objectLeft1.x = centerX - conveyorWidth / 2 - objDisplace;
        this.objectRight1.x = centerX + conveyorWidth / 2 + objDisplace;

        this.objectLeft1.y = floorLine - 235;
        this.objectRight1.y = this.objectLeft1.y;

        
        const objSkew = Utils.lerp(doorPhase, 0.085, -0.08);
        this.objectLeft1.skew.x = objSkew;
        this.objectRight1.skew.x = -objSkew;

        // 
        // this.conveyor.width = Utils.lerp(doorPhase, 660, 890);

    }


}

export default BackgroundG2;