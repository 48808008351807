import { AppConfig } from "../../config/AppConfig";
import GameModel from "../../model/GameModel";
import EMessages from "../../services/EMessages";
import ResourceList from "../../services/ResourceList";
import SpriteCommon from "../components/common/SpriteCommon";
import gsap from 'gsap';

class PopupAbstract {
    /**
     * @param {GameModel} gameModel 
     */
    constructor(gameModel){
        this.gameModel = gameModel;
        this._visible = undefined;
        
    }

    /**
     * 
     * @param {string} domID 
     */
    setLinks(domID) {
        this.domID = domID;
        const lang = AppConfig.lang.toLowerCase();
        this.domLink = document.getElementById(domID + lang);
        this.popupWindow = document.querySelector(`#${domID}${lang} .popup`);
        this.playAgainBtnDOM = document.querySelector(`#${domID}${lang} .play-again-btn`);
        this.closeBtnDOM = document.querySelector(`#${domID}${lang} .close-btn`);
        this.scoresText = document.querySelector(`#${domID}${lang} .scores-value`);
        if(this.playAgainBtnDOM) this.playAgainBtnDOM.cursor = "pointer";

        if (this.closeBtnDOM) {
            this.closeBtnDOM.cursor = "pointer";

            this.closeBtnDOM.addEventListener('pointerdown', () => {
                this.gameModel.registerPlayAgainInteraction();
                this.visible = false;
            });
        }
    }

    /**
     * 
     * @param {number} value 
     */
    displayScore(vlue) {
        this.scoresText.innerHTML = vlue;
    }

    /**
     * @type {boolean}
     */
    get visible(){ return this._visible}

    set visible(value) {
        if (this._visible === value) return
        if (this.scoresText) this.displayScore(this.gameModel.scores);
        this._visible === value;
        // this.domLink.style.display = value ? "flex" : "none";

        const lang = AppConfig.lang.toLowerCase();

        if (value) {
            this.domLink.style.display = "flex";
            // this.domLink.style.opacity = 0.3;
            gsap.to("#" + this.domID + lang, {
                opacity: 1,
                duration: 1,
                ease: 'power3.out'
                });
        } else {
            gsap.to("#" + this.domID + lang, {
                opacity: 0,
                duration: 1,
                ease: 'power3.out',
                onComplete: () => {
                    this.domLink.style.display = "none";
                },
                });
        }

    }
}

export default PopupAbstract;