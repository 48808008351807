import { AppConfig } from "../config/AppConfig";
import EGameNames from "../config/EGameNames";
import BackgroundG1 from "./components/backgrounds/BackgroundG1";
import BackgroundG2 from "./components/backgrounds/BackgroundG2";
import BackgroundG3 from "./components/backgrounds/BackgroundG3";
import BackgroundG4 from "./components/backgrounds/BackgroundG4";

class SkinClassesFactory {

    /**
     * @access public 
     */
   static getCurrentBackgroundClass() {
        const gameID = AppConfig.gameID;
        const backgroundClassMapping = {
            [EGameNames.CINEPLEX_G1]: BackgroundG1,
            [EGameNames.EMPIRE_G2]: BackgroundG2,
            [EGameNames.SCOTIA_G3]: BackgroundG3,
            [EGameNames.SCENEPLUS_G4]: BackgroundG4,
        };
        return backgroundClassMapping[gameID] || null;
    }
}

export default SkinClassesFactory

// Import background classes separately
/* switch (gameName) {
    case EGameNames.CINEPLEX_G1:
        import BackgroundG1 from "./components/backgrounds/BackgroundG1";
        return BackgroundG1;
    case EGameNames.EMPIRE_G2:
        import BackgroundG1 from "./components/backgrounds/BackgroundG1";
        return BackgroundG2;
    case EGameNames.SCOTIA_G3:
        import BackgroundG1 from "./components/backgrounds/BackgroundG1";
        return BackgroundG3;
    case EGameNames.SCENEPLUS_G4:
        import BackgroundG1 from "./components/backgrounds/BackgroundG1";
        return BackgroundG4;
} */



