import EItemsID from "./EItemsID";
import CryptoJS from "crypto-js";

class GameStat {
    constructor() {
        this.itemsHistory = [];
    }

    /**
     * @access package
     */
    resetStats() {
        this.itemsHistory = [];

    }

    /**
     * @access package
     */
    storeСaughtItem(itemKind) {
        this.itemsHistory.push(itemKind);
    }

    /**
     * @access package
     * @returns {string}
     */
    getItemsHistoryString() {
        let itemsString = "";
        this.itemsHistory.forEach(itemKind => {
            itemsString += (itemKind.numId).toString();
        });

            const itemsImage = CryptoJS.AES.encrypt(itemsString, 'f0227a92774111eeb9620242ac120002').toString();
            return itemsImage
    }

    /**
    * @access package
    * @returns {{
    *   plus10: number,
    *   plus20: number,
    *   minusNpoints: number,
    *   minusNseconds: number,
    *   plusNseconds: number,
    *   magnet: number,
    *   speedUp: number
    * }} 
    */
    getItemsStat() {
        const items = {
            [EItemsID.PLUS10]: 0,
            [EItemsID.PLUS20]: 0,
            [EItemsID.MINUS_N_POINTS]: 0,
            [EItemsID.MINUS_N_SECONDS]: 0,
            [EItemsID.PLUS_N_SECONDS]: 0,
            [EItemsID.MAGNET]: 0,
            [EItemsID.SPEED_UP]: 0,
        };
        
        this.itemsHistory.forEach(itemKind => {
            if (items.hasOwnProperty(itemKind.id)){
                items[itemKind.id]++;
            } else {
                const error = new Error("UNKNOWN_ITEM", "Unkonwn item error");
                console.log(error.message);
            }
            
        });
        return items
    }


}

export default GameStat