import * as PIXI from 'pixi.js';
import { AppConfig } from './config/AppConfig';
import Game from "./Game";
import { extendPIXI } from "./view/utils/ExtendPIXI"
import AnalyticsService from './services/AnalyticsService';


AppConfig.updateSize(window.innerWidth, window.innerHeight);
const { gameWidth, gameHeight } = AppConfig.settings;

window.PIXI =  PIXI

extendPIXI()

const options = {
    width: gameWidth,
    height: gameHeight,
    background: 0x000000,
    // antialias: true,
    transparent: true,
    resolution: 2,
    // resizeTo: window
};
const app = new PIXI.Application(options);

window['globalThis']['__PIXI_APP__'] = app;

const onResize = () => {
    app.renderer.resize(window.innerWidth, window.innerHeight);
    AppConfig.updateSize(window.innerWidth, window.innerHeight);
}

const init = () => {
  AnalyticsService.init();

  window.addEventListener('resize', onResize);
  const game = new Game(app);
  AppConfig.game  =  game;
  app.stage.addChild(game);
  onResize();
  document.body.appendChild(app.view);
  const domReport = document.getElementById('reportMessages');
  // domReport.style.display = AppConfig.isDebug  ? "block" : "none";
  if (AppConfig.isDebug) {
      AppConfig.settings3D.showConeyor3Dhelper = false;
      AppConfig.settings.useShadowsForProducts = false;
      AppConfig.settings.soundsByDefault = false;
    //   AppConfig.settings.keyPadAlpha = 0.2;
      AppConfig.gameSettings.speedUpDuration = 30000;
      game.gameModel.timeLeft = 10;
      AppConfig.gameSettings.maxScores = 100;
      AppConfig.gameSettings.counDownStepDuration = 0.01;
  }
  return game
};

export { EventBus, app, init };
