import * as PIXI from 'pixi.js';
import { AppConfig } from "../../../config/AppConfig";
import ResourceList from "../../../services/ResourceList";
import BackgroundAbstract from "./BackgroundAbstract";
import FixiedTilingSprite from './FixiedTilingSprite';
import SpriteCommon from '../common/SpriteCommon';
import Background3D from './Background3D';

class BackgroundG3 extends BackgroundAbstract{
    constructor() {
        super();
    }

     /**
     * @access public
     */
     createElements() {
        
        this.wall = new FixiedTilingSprite(ResourceList.BG_3_WALL, 512, 343);
        this.floor = new FixiedTilingSprite(ResourceList.BG_3_FLOOR, 800, 300);
        this.door = new SpriteCommon(ResourceList.BG_3_DOOR);
        // this.banner = new SpriteCommon(ResourceList.MSC_3_BANNER);
        this.banner = new SpriteCommon(ResourceList["MSC_3_BANNER_" + AppConfig.lang]);

        super.createElements();

        const { gameWidth, gameHeight } = AppConfig.settings;
        

        this.objectLeft1 = new SpriteCommon(ResourceList.BG_3_OBJ_LEFT_1);
        this.objectLeft2 = new SpriteCommon(ResourceList.BG_3_OBJ_LEFT_2);
        this.objectRight1 = new SpriteCommon(ResourceList.BG_3_OBJ_RIGHT_1);
        this.objectRight2 = new SpriteCommon(ResourceList.BG_3_OBJ_RIGHT_2);
        this.objectRight3 = new SpriteCommon(ResourceList.BG_3_OBJ_RIGHT_3);


        this.objectLeft1.anchor.set(1, 0);
        this.objectRight1.anchor.set(0, 0);
        this.objectLeft2.anchor.set(0, 0.5);
        this.objectRight2.anchor.set(1, 0.5);
        this.objectRight3.anchor.set(1, 1);

        
        this.addChild(this.objectLeft2);
        this.addChild(this.objectRight2);
        this.addChild(this.objectRight3);
        this.addChild(this.objectLeft1);
        this.addChild(this.objectRight1);

        this.createElementsAfter();
    }


    /**
     * @access public
     */
    onResize() {
        super.onResize();
        const { gameWidth, gameHeight } = AppConfig.settings;
        const { horyzontPos } = AppConfig.settings3D;
        const { floorLineFrom3DCenter } = AppConfig.settings3D;
        const centerY = gameHeight * horyzontPos;
        const floorLine = centerY + floorLineFrom3DCenter;;
        
        const centerX = gameWidth / 2;
        

        const conveyorWidth = 330 * this.door.scale.x;

        this.objectLeft1.x = centerX - conveyorWidth / 2;
        this.objectRight1.x = centerX + conveyorWidth / 2;

        this.objectLeft1.y = floorLine - 100;
        this.objectRight1.y = this.objectLeft1.y;

        
        const objectLeft2MinX = this.objectLeft1.x - this.objectLeft1.width - this.objectLeft2.width + 80;
        this.objectLeft2.x = -10 < objectLeft2MinX ? -10 : objectLeft2MinX;

        const objectRight2MaxX = this.objectRight1.x + this.objectRight1.width + this.objectRight2.width - 140;
        this.objectRight2.x = objectRight2MaxX > gameWidth  ? objectRight2MaxX : gameWidth;

        const objectRight3MaxX = objectRight2MaxX - 50;
        this.objectRight3.x = objectRight3MaxX > gameWidth  ? objectRight3MaxX : gameWidth;


        this.objectLeft2.y = floorLine - 0;
        this.objectRight2.y = floorLine;

        this.objectRight3.y = gameHeight;

    }


}

export default BackgroundG3;