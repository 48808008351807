import { Assets, Sprite, Loader } from 'pixi.js';
import * as PIXI from 'pixi.js';
import ResourceList from "./ResourceList";
class ResourceService {
}
ResourceService.init = (onSuccess) => {
    Assets.load(ResourceList.LIST).then(() => onSuccess());
}; 

/*
ResourceService.init = (onSuccess, onError) => {
    Assets.load(ResourceList.LIST)
        .then(() => onSuccess())
        .catch((error) => {
            // console.error('Error loading resources:', error);
            console.log(error);
            if (onError) {
                onError(error);
            }
        });
}; */


/* ResourceService.getTexture = (resourceName) => {
    return Assets.get(resourceName);
}; */

ResourceService.getTexture = (resourceName) => {
    if (resourceName === undefined) {
        const stackTrace = (new Error()).stack;
        console.error('undefined texture name was called from:', stackTrace);
        return null;
    }
    try {
        return Assets.get(resourceName);
    } catch (error) {
        // Capture stack trace
        const stackTrace = (new Error()).stack;
        console.error(`Error retrieving texture for resource '${resourceName}':`, error);
        console.error('Stack trace:', stackTrace);
        return null;
    }
};


ResourceService.getSprite = (resourceName) => {
    return new Sprite(ResourceService.getTexture(resourceName));
};
ResourceService.getSpriteSheet = (resourceName) => {
    return Assets.get(resourceName);
};
ResourceService.getSound = (resourceName) => {
    return Assets.get(resourceName);
};
export default ResourceService;
