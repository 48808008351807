import * as PIXI from 'pixi.js';
import Utils3D from '../../utils/Utils3D';


class Background3D extends PIXI.Graphics{
    constructor() {
        super();
        this.alpha = 0.2;
        
        this.redraw = () => {
            this.clear();            
            this.beginFill(0x993333);
    
            const { topLeft, topRight, bottomRight, bottomLeft } = Utils3D.updateConveyorCorners();
    
            const vertices = [
                topLeft, topRight,
                bottomRight, bottomLeft
            ];
    
            this.drawPolygon(vertices);
        }

        // AppConfig.sizeUpdated.add(this.redraw);
        // this.redraw();
    }



}

export default Background3D;