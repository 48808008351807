import * as PIXI from 'pixi.js';
import { AppConfig } from "../../../config/AppConfig";
import ResourceList from "../../../services/ResourceList";
import BackgroundAbstract from "./BackgroundAbstract";
import FixiedTilingSprite from './FixiedTilingSprite';
import SpriteCommon from '../common/SpriteCommon';
import Background3D from './Background3D';

class BackgroundG1 extends BackgroundAbstract{
    constructor() {
        super();
    }

     /**
     * @access public
     */
     createElements() {
        
        this.wall = new FixiedTilingSprite(ResourceList.BG_1_WALL, 512, 343);
        this.floor = new FixiedTilingSprite(ResourceList.BG_1_FLOOR, 800, 600);
        this.door = new SpriteCommon(ResourceList.BG_1_DOOR);
        this.banner = new SpriteCommon(ResourceList.MSC_1_BANNER);

        this.floor.visible = false;

        super.createElements();

        const { gameWidth, gameHeight } = AppConfig.settings;
        

        this.chairsRowsLeft = new SpriteCommon(ResourceList.BG_1_CHAIRS_ROWS);
        this.chairsRowsRight = new SpriteCommon(ResourceList.BG_1_CHAIRS_ROWS);

        this.chairsRowsLeft.scale.set(1, 1)
        this.chairsRowsRight.scale.set(-1, 1)

        this.chairsRowsLeft.anchor.set(1, 0);
        this.chairsRowsRight.anchor.set(1, 0);

        this.addChild(this.chairsRowsLeft);
        this.addChild(this.chairsRowsRight);

        this.createElementsAfter();
    }


    /**
     * @access public
     */
    onResize() {
        super.onResize();
        const { gameWidth, gameHeight } = AppConfig.settings;
        const { horyzontPos } = AppConfig.settings3D;
        const { floorLineFrom3DCenter } = AppConfig.settings3D;
        const centerY = gameHeight * horyzontPos;
        const floorLine = centerY + floorLineFrom3DCenter;;
        
        const centerX = gameWidth / 2;
        

        const conveyorWidth = 330 * this.door.scale.x;

        this.chairsRowsLeft.x = centerX - conveyorWidth / 2;
        this.chairsRowsRight.x = centerX + conveyorWidth / 2;

        this.chairsRowsLeft.y = floorLine - 100;
        this.chairsRowsRight.y = this.chairsRowsLeft.y;

        if (AppConfig.settings3D.showConeyor3Dhelper) this.bg3D.redraw();
    }


}

export default BackgroundG1;