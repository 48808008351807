import * as PIXI from 'pixi.js';
import { Text } from 'pixi.js';
import GameModel, { EGameStates } from '../../model/GameModel';
import SpriteCommon from "../components/common/SpriteCommon";
import ResourceList from "../../services/ResourceList";
import gsap from "gsap";
import { AppConfig } from '../../config/AppConfig';
import ItemSprite from '../components/items/ItemSprite';
import { Cart, CartOver } from '../components/items/Cart';
import Countdown from '../components/CountDown';
import ScoreBallon from '../components/ScoreBallon';
import { SoundManager } from '../../services/SoundManager';
import KeyPad from '../components/KeyPad';
import EItemsID from '../../model/EItemsID';
import PanelInfo from '../components/panels/PanelInfo';
import Fireworks from '../components/effects/Fireworks';
import Utils3D from '../utils/Utils3D';
import ReglesDOM from '../doms/ReglesDOM';
import EMessages from '../../services/EMessages';
import DebugService from '../../services/DebugService';
import SoundData from '../../services/SoundData';
import PanelControls from '../components/panels/PanelControls';
import ParticleEmitterFactory from '../components/effects/ParticleEmitterFactory';
import SkinClassesFactory from '../SkinClassesFactory';
import { Utils } from '../utils/Utils';
import FinishPopUp from '../doms/FinsihPopUp';
import FinishWithPrizePopUp from '../doms/FInishWithPrizePopUp';
import PausePopUp from '../doms/PausePopUp';

// const { gameWidth, gameHeight } = AppConfig.settings;
// const { animationSpeed, worldSize, conveyorY, conveyorWidth, zCartPosition, zDeep} = AppConfig.settings3D;
// const { levelMaxScores, newItemDelay } = AppConfig.gameSettings;


class GameScreen extends PIXI.Container {
    static isMouseDown = false;
    /**
     * @param {PIXI.Application} app
     * @param {GameModel} gameModel
     */
    constructor(app, gameModel) {
        super();
        this.app = app;
        this.gameModel = gameModel;
        this.soundManager = new SoundManager();
        const BgClass = SkinClassesFactory.getCurrentBackgroundClass();
        this.bg = new BgClass()

        this.items = [];
        this.itemsCont = new PIXI.Container;
        this.mist = new SpriteCommon(ResourceList.MSC_MIST);
        this.mist.anchor.set(0.5, -0.3);
        this.mist.scale.set(0.9, 0.7);
        this.mist.alpha = 0.6;

        this.cart = new Cart();
        this.cartOver = new CartOver(this.gameModel);

        this.scoreBallonsCont = new PIXI.Container;
        this.keyPad = new KeyPad(gameModel);
        this.panelInfo = new PanelInfo(gameModel, this);
        this.fireworks = new Fireworks();
        this.emitter = ParticleEmitterFactory.initEmitter(this);
        this.topEmitter = ParticleEmitterFactory.initTopEmitter(this);
        this.speedUpImage = new SpriteCommon(ResourceList.MSC_SPEEDUP_EFFECT);

        this.countdown = new Countdown(this.gameModel, this);
        this.countdown.alpha = 1;
        this.rulesDOM = new ReglesDOM(this.gameModel);
        this.finishPopUp = new FinishPopUp(this.gameModel);
        this.finishWithPrizePopUp = new FinishWithPrizePopUp(this.gameModel);
        this.pausePopUp = new PausePopUp(this.gameModel, this.finishWithPrizePopUp);
        this.panelControls = new PanelControls(gameModel, this);

        this.initialSpeed = this.gameModel.speed * this.gameModel.speedUpFactor;
        this.t = 0;

        this.addElements = () => {
          const { gameWidth, gameHeight } = AppConfig.settings;

          this.addChild(this.bg);
          this.bg.anchor
          this.addChild(this.itemsCont);
          this.addChild(this.emitter);

          this.addChild(this.cart);
          this.addChild(this.topEmitter);
          this.addChild(this.mist);

          this.cart.scale.set(1);
          this.cart.anchor.set(0.5, 1);
          this.cart.y = gameHeight;
          this.cart.x = gameWidth / 2;
          this.addChild(this.cartOver);
          this.cartOver.scale.set(1);
          this.cartOver.anchor.set(0.5, 1);
          this.cartOver.y = gameHeight;
          this.cartOver.x = gameWidth / 2;
          this.addChild(this.panelInfo);
          // this.addChild(this.fireworks);
          this.addChild(this.speedUpImage);
          this.speedUpImage.visible = false;

          this.tempHelper = new SpriteCommon(ResourceList.MSC_STAR);
          this.tempHelper.width = 12;
          this.tempHelper.height = 12;
          this.tempHelper.anchor.set(0.5, 0.5);
          if (AppConfig.isDebug) this.addChild(this.tempHelper);


          this.claculateParams();

          this.addChild(this.keyPad);

          this.panelInfo.x = 10;
          this.panelInfo.y = 20;
          this.panelControls.y = 20;

          this.addChild(this.scoreBallonsCont);
          this.updateScores();
          this.updateTimeLeft();

          // this.countdown.position.set(app.screen.width / 2, app.screen.height / 2);
          this.addChild(this.countdown);
          this.countdown.on('countdownStarted', () => {
              this.acitvateSounds();
          });
          this.countdown.on('countdownComplete', () => {
              this.gameModel.sendMessage(EMessages.SND_GAME_START)
              this.start();
          });
          this.addChild(this.panelControls);

          this.onResize();

        };

        this.count = 1;

        this.updateScores = (item, scores) => {
            const { levelMaxScores } = AppConfig.gameSettings;
            this.addScoreBallon(item, 'scores', scores);
        };

        this.updateTimeLeft = (item, timeIncrement) => {
            if (timeIncrement > 0 || timeIncrement < -3) this.addScoreBallon(item, 'time', timeIncrement);
        };

        this.onGameStateUpdated = () => {
            if (this.gameModel.gameState === EGameStates.playing){
                this.soundManager.play(SoundData.LOOP_BG_MUSIC);
                if (this.gameModel.isMagnet) this.soundManager.play(SoundData.LOOP_MAGNET);
                if (this.gameModel.speedUpFactor > 1) this.soundManager.play(SoundData.LOOP_SPEEDUPT);
            } else {
              this.soundManager.stopGameSounds();
            }
          if (this.gameModel.gameState === EGameStates.stop){
            this.soundManager.play(SoundData.MSC_FINISH);
          }

        };

        this.onCartLineUpdated = () => {
            const { gameWidth } = AppConfig.settings;

            const f = 0.5 * this.gameModel.cartLine;
            const conveyorWidth2D = gameWidth * 0.5;
            // const trgX = gameWidth / 2   + conveyorWidth2D * f;
            const trgX = Utils3D.getCartXPosByPosInRow(this.gameModel.cartLine);

            gsap.to(this.cart, { x: trgX, duration: 1.1, ease: "elastic.out(1.2, 0.9, 1.05, 0.98)"});
            gsap.to(this.cartOver, { x: trgX, duration: 1.1, ease: "elastic.out(1.2, 0.9, 1.05, 0.98)"});

        };

        this.onExtraCoutch = (item) => {
            if (item.itemKind.id === EItemsID.SPEED_UP || item.itemKind.id === EItemsID.MAGNET){
                this.addScoreBallon(item, item.itemKind.id);
            }
        };

 
        this.gameModel.extraStatusUpdated.add((extraID, isOn) => {
          if (extraID === EItemsID.SPEED_UP) {
            if (isOn) {
              const { gameWidth, gameHeight } = AppConfig.settings;
              const { horyzontPos } = AppConfig.settings3D;
              const { doorBottom, doorTop } = AppConfig.dynamicHelpers;

              const horizontInDoor = doorTop + (doorBottom - doorTop) * 0.25;
              this.topEmitter.start(gameWidth / 2, horizontInDoor, 5, 'speedFX');
            //   this.topEmitter.start(gameWidth / 2, gameHeight * horyzontPos, 5, 'speedFX');
              this.soundManager.play(SoundData.LOOP_SPEEDUPT);

            } else {
              this.topEmitter.stopAll('speedFX');
              this.soundManager.stop(SoundData.LOOP_SPEEDUPT);
            }
          }

          if (extraID === EItemsID.MAGNET) {
            if (isOn) {
              this.soundManager.play(SoundData.LOOP_MAGNET);
            } else {
              this.soundManager.stop(SoundData.LOOP_MAGNET);
            }
          }

        });


        this.onSpeedUpdated = () => {
            this.reRunAddRowInterval();
        };

        this.onSteakMultiplierUpdated = () => {
            const { gameWidth, gameHeight } = AppConfig.settings;
            const { horyzontPos} = AppConfig.settings3D;
            this.bg.showHideLights(this.gameModel.steakMultiplier > 1);
            const target = { x: gameWidth / 2, y: gameHeight * horyzontPos};
            this.addScoreBallon(target, 'steakMultiplier', this.gameModel.steakMultiplier);
        };


        this.onGameRestarted = (item) => {
            this.removeAllItems();
            this.countdown.resetCountDown();
            this.presetItemsOnConveyor();
        };

        this.items = [];
        this.eventMode = `dynamic`;

        this.gameModel.scoreUpdated.add(this.updateScores);
        this.gameModel.timeLeftUpdated.add(this.updateTimeLeft);
        this.gameModel.gameStateUpdated.add(this.onGameStateUpdated);
        this.gameModel.cartLineUpdated.add(this.onCartLineUpdated);
        this.gameModel.extraCoutch.add(this.onExtraCoutch);
        this.gameModel.speedUpdated.add(this.onSpeedUpdated);
        this.gameModel.gameRestarted.add(this.onGameRestarted);
        this.gameModel.steakMultiplierUpdated.add(this.onSteakMultiplierUpdated);

        this.init();
      AppConfig.sizeUpdated.add(this.onResize.bind(this));
    }

    init() {
        this.addElements();
        this.presetItemsOnConveyor();
        this.step = 0;
        this.deltasum = 0;
        this.app.ticker.add((delta) => {
            if (this.gameModel.gameState !== EGameStates.playing) return
            this.t += delta;
            this.gameModel.updateLastMSTime(this.app.ticker.lastTime);
            const speed = this.blockSpace3Dz * (this.app.ticker.deltaMS / (this.gameModel.speed * 1000));
            // const speed = this.blockSpace3Dz * (delta / )
            this.items.forEach(c => { c.point3D.z -= speed * this.gameModel.speedUpFactor; });

            if (this.cartOver) this.cartOver.animate();
            this.panelInfo.updateExtras();
            this.fireworks.update();
            this.step++;
            this.deltasum += delta;
            if (this.step % 60 === 0) {
                const rep = Math.round(this.deltasum * 100) / 100;
                // const fps = Math.round(this.deltasum * 100) / 100;
                const fpm = 60 / this.deltasum;
                const fps = 60 * fpm;
                DebugService.log(fps);
                this.deltasum = 0;
                // console.lof(Math.random());
            }


        });

        window.addEventListener('keydown', (e) => {
            // if (this.gameModel.gameState !== EGameStates.playing) return
            if (e.code === 'ArrowRight') {
                this.gameModel.registerMoveCart(false);
            } else if (e.code === 'ArrowLeft') {
                this.gameModel.registerMoveCart(true);
            };
        });

        window.addEventListener('mousedown', function(event) {
            if (event.button === 0) {
                GameScreen.isMouseDown = true;
                // console.log("MouseDown");
            }
        });
        
        window.addEventListener('mouseup', function(event) {
            if (event.button === 0) {
                GameScreen.isMouseDown = false;
                // console.log("MouseUp");
            }
        });

    };


    acitvateSounds() {
        //we activate them on first user action
        this.soundManager.init();
    }

    start() {
        this.gameModel.resumeGame();
        this.reRunAddRowInterval();

    }

    moveToCart(item) {
        this.cart.cloneItem(item);
    };

    animate (delta = 0) {
    };

    onResize(item) {
        const {gameWidth, gameHeight} = AppConfig.settings;
        const {horyzontPos} = AppConfig.settings3D;

        const convWidth = AppConfig.dynamicHelpers.conveyorBottomCorner * 2;
        //0.56 and 0.38 are defined in Figma that inverse are 1.77 and 2.63 
        if ( convWidth < gameWidth) {
            const diff = gameWidth - convWidth;
            // const diffPhase = Utils.inverseLerp(diff, 0, 200);
            // const cartDesk = Utils.lerp(diffPhase, 1.92, 2.63);
            // this.cart.width = convWidth / cartDesk;
            this.cart.width = convWidth / 2.36;
        } else { 
            this.cart.width = gameWidth / 2.36;
            if (this.cart.width < 100) this.cart.width = 100;
        }
        const cartScaleX = this.cart.scale.x;
        this.cart.scale.set(cartScaleX, cartScaleX);
        this.cartOver.scale.set(cartScaleX, cartScaleX);

        AppConfig.dynamicHelpers.cartHeight = this.cart.height;
        this.items.forEach((item) => {
        item.updatePosByPoint3D();
        this.bg.onResize()
    });

    this.mist.x = gameWidth / 2;
    this.mist.y = gameHeight * horyzontPos;
    this.countdown.position.set(gameWidth / 2, gameHeight / 2);

    this.cart.y = gameHeight;

    this.cartOver.y = gameHeight;


    this.onCartLineUpdated();

    if (this.speedUpEffectConfig && this.speedUpEffectConfig.origin) {
        this.speedUpEffectConfig.origin.x = gameWidth / 2;
        this.speedUpEffectConfig.origin.y = gameHeight * horyzontPos;
    }

    this.tempHelper.y = gameHeight - 10;
    this.tempHelper.x = gameWidth / 2 + AppConfig.dynamicHelpers.conveyorBottomCorner;
    // this.tempHelper.x = gameWidth / 2 + 300;
  }

    reRunAddRowInterval() {
        if (this.newItemInterval){
            clearInterval(this.newItemInterval);
        }
        const currentAddItemInterval = (this.gameModel.speed * 1000) / this.gameModel.speedUpFactor;
        this.newItemInterval = setInterval(() => {
            if (this.gameModel.gameState !== EGameStates.playing) return
            const newItems = this.addItemsRow();
            this.test_checkDistance();
        }, currentAddItemInterval);
    }

    /**
     *
     * @returns {Array.<ItemSprite>}
     */
    addItemsRow() {
        const { worldSize, conveyorY, zDeep } = AppConfig.settings3D;

        const modelItemsArray = this.gameModel.getNextItemModelsRow()
        const itemsArray = []
        for (let i = 0; i < modelItemsArray.length; i++) {
            const itemModel = modelItemsArray[i];
            const item = new ItemSprite(itemModel.posInRow, itemModel.itemKind, this.gameModel, this);
            itemsArray.push(item);
            item.anchor.set(0.5, 1);
            item.outOfBoundsCallback = () => this.onItemOutOfBounds(item);
            const yPosOnConveyor = conveyorY * worldSize;
            const xPosOnConveyor = this.get3DXByPosInRow(itemModel.posInRow);
            item.point3D.setPositions(xPosOnConveyor, yPosOnConveyor, zDeep);
            item.axis3Dx = xPosOnConveyor;
            this.itemsCont.addChild(item);
            this.items.push(item);
            this.count++;
            this.sortItems();
            item.alpha = 0;
            const appearingDuration = 4 / (this.gameModel.speed * this.gameModel.speedUpFactor);
            // gsap.to(item, { alpha: 1, duration: appearingDuration, onComplete: () => { item.alpha = 1; } });
        }
        return itemsArray

    };

   sortItems() {
        this.items.sort((a, b) => {
            a.point3D.z - b.point3D.z
        });

        this.items.forEach((item, index) => {
          item.zIndex = 0xffffff - index;
        });
        this.itemsCont.sortChildren();
      }

    removeItem(item) {
        this.itemsCont.removeChild(item);
        const index = this.items.indexOf(item, 0);
        if (index > -1) {
            this.items.splice(index, 1);
        }
    };

    claculateParams() {
        const { zCartPosition, zDeep} = AppConfig.settings3D;

        const zLeng = zDeep - zCartPosition;
        const n = 10;
        this.blockSpace3Dz = zLeng / n;
    }

    presetItemsOnConveyor() {
        const { zDeep } = AppConfig.settings3D;

        const n = 10;
        for (let i = n - 1; i >= 0; i--) {
            let itemsRow = this.addItemsRow();
            for (let r = 0; r < itemsRow.length; r++) {
                let item = itemsRow[r];
                item.point3D.z = zDeep - i * this.blockSpace3Dz;
                item.axis3Dx = this.get3DXByPosInRow(item.posInRow);
            }

        }
        this.sortItems();
    };

    get3DXByPosInRow(pos) {return Utils3D.get3DXByPosInRow(pos)}

    getRandomInt(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min) + min);
      }

    onItemOutOfBounds(item) {
        this.removeItem(item);
        item.destroy();
    }

    /**
     * @param {*} item
     * @param {( 'scores' | 'time')} item
     * @param {*} value
     */
    addScoreBallon(item, type, value) {
        const { gameWidth, gameHeight } = AppConfig.settings;

        if (item === undefined) return
        let point = item ? {x:item.x, y:item.y} : {x:gameWidth / 2, y:gameHeight / 2};
        this.fireworks.setImmiterPos(point.x, point.y);
        const pos = item?.posInRow !== undefined ? item?.posInRow : 0;
        const scoreBallon = new ScoreBallon(type, value, point, pos, item?.itemKind);
        this.scoreBallonsCont.addChild(scoreBallon);
        scoreBallon.on('finish', () => {
            this.scoreBallonsCont.removeChild(scoreBallon);
            scoreBallon.removeAllListeners('finish');
        });

        if (item?.itemKind?.id) {
            this.soundManager.playItemCautch(item.itemKind.id);
        } 
    }

    getCartXByLine(linePos) {

    }

    removeAllItems() {
        this.items.splice(0,this.items.length);
        this.itemsCont.removeChildren();
        this.cart.removeAllItems();
    }

    test_checkDistance() {
        let distances = [];
        for (let i = 0; i < this.items.length; i++){
            let item = this.items[i];
            if (i < this.items.length - 1) {
                let diff = this.items[i + 1].point3D.z - this.items[i].point3D.z
                distances.push(diff);
            }
        }
    }

    switchBG() {

    }

    switchMouseMode() {

    }
}
export default GameScreen;
