import { AppConfig } from "../../config/AppConfig";
import GameModel, { EGameStates } from "../../model/GameModel";
import AnalyticsEvents from "../../services/AnalyticsEvents";
import AnalyticsService from "../../services/AnalyticsService";
import PopupAbstract from "./PopupAbstract";

class FinishWithPrizePopUp extends PopupAbstract{
    /**
     * 
     * @param {GameModel} gameModel 
     */
    constructor(gameModel) {
        super(gameModel);
        this.setLinks('finish-game-prize-');

        if (this.domLink && this.playAgainBtnDOM) {
            this.playAgainBtnDOM.cursor = "pointer";

            this.playAgainBtnDOM.addEventListener('pointerup', () => {
                AnalyticsService.logEvent(AnalyticsEvents.WINSCREEN_PLAY_AGAIN);
                this.gameModel.registerPlayAgainInteraction(true);
                this.visible = false;

            });
        }

        if (this.domLink && this.copyCodeBtnDOM) {
            this.copyCodeBtnDOM.cursor = "pointer";

            this.copyCodeBtnDOM.addEventListener('pointerup', () => {
                // this.copyTextToClipboard();
                // this.navigateToPrizePage();
                this.fallbackCopyTextToClipboard("FREEDORITOS");
            });
        }

        if (this.domLink && this.copyCodeIcon) {
            this.copyCodeIcon.cursor = "pointer";

            this.copyCodeIcon.addEventListener('pointerup', () => {
                // this.copyTextToClipboard();
                // this.navigateToPrizePage();
                this.fallbackCopyTextToClipboard("FREEDORITOS");
            });
        }

        this.gameModel.gameStateUpdated.add(() => {
            if (this.gameModel.gameState === EGameStates.stop){
                if (this.gameModel.scoresAchived) {
                    this.visible = true;
                } else {
                    this.visible = true;
                }
            } else if (this.gameModel.gameState === EGameStates.playing){
                this.visible = false;

            }
            
        })
    }

    setLinks(domID) {
        super.setLinks(domID);
        const lang = AppConfig.lang.toLowerCase();
        this.codeForCopyDOM = document.querySelector(`#${domID}${lang} .code-for-copy`);
        this.copyCodeBtnDOM = document.querySelector(`#${domID}${lang} .copy-code-btn`);
        this.copyReportDOM = document.querySelector(`#${domID}${lang} .copy-report`);
        this.copyCodeIcon  = document.querySelector(`#${domID}${lang} .copy-code-icon`);
    }

    copyCodeToClipboardFromTextArea(){
        this.codeForCopyDOM.focus();
        this.codeForCopyDOM.select();
      
        try {
          var successful = document.execCommand('copy');
          var msg = successful ? 'successful' : 'unsuccessful';
          if (successful) {
            //this.copyReportDOM.innerHTML = "the code has been copied";
          } else {
            //this.copyReportDOM.innerHTML = "the code hasn't been copied";
          }

        } catch (err) {
            //this.copyReportDOM.innerHTML = "unable to copy";
        }
    }

    copyTextToClipboard() {
        const text = "FREEDORITOS";
        if (!navigator.clipboard) {
          this.fallbackCopyTextToClipboard(text);
          return;
        }
        navigator.clipboard.writeText(text).then(function() {
          console.log('Async: The code has been copied');   
        }, function(err) {
          console.error('Async: Could not copy the code: ', err);
        });
      }

    fallbackCopyTextToClipboard(text) {

        AnalyticsService.logEvent(AnalyticsEvents.WINSCREEN_COPY_CODE);
        var textArea = document.createElement("textarea");
        textArea.value = text;
        
        // Avoid scrolling to bottom
        textArea.style.top = "0";
        textArea.style.left = "0";
        textArea.style.position = "fixed";
      
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
      
        try {
          var successful = document.execCommand('copy');
          var msg = successful ? 'successful' : 'unsuccessful';
          console.log('Fallback: Copying text command was ' + msg);
          this.codeForCopyDOM.style.color = "#e1ba0b";
          
        } catch (err) {
          console.error('Fallback: Oops, unable to copy', err);
        }
      
        document.body.removeChild(textArea);
    }

    navigateToPrizePage(){
        AnalyticsService.logEvent(AnalyticsEvents.WINSCREEN_COPY_CODE);
        window.location.href = 'https://getir.onelink.me/E7dL/81wbgdlj';
    }
 

    /**
     * @type {boolean}
     */
    set visible(value) {
        super.visible = value;
        if (value) {
            AnalyticsService.logEvent(AnalyticsEvents.WIN_SCREEN);
        } 

    }

}

export default FinishWithPrizePopUp