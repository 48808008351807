import { AppConfig } from "./AppConfig";

class Language {

    /**
     * 
     * @param {en:string, fr:string} text 
     * @returns string
     */
    static getText(text) {
        return text[AppConfig.lang.toLowerCase()];
    }

}

Language.OUCH = {en:"OUCH!", fr:"AUW!"};
Language.SUPER = {en:"SUPER!", fr:"SUPER!"};
Language.INCREDIBLE = {en:"INCREDIBLE!", fr:"ONGELOOFLIJK!"};



export default Language;