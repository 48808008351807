import ResourceList from "../../../services/ResourceList";
import InfoBox from "./InfoBox";
import InfoBoxWithIcon from "./InfoBoxWithIcon";

import ResourceList from "../../../services/ResourceList";
import InfoBox from "./InfoBox";
import InfoBoxWithIcon from "./InfoBoxWithIcon";
class ScoreInfo extends InfoBoxWithIcon {
    constructor() {
        super(ResourceList.MSC_COIN_BIG);
        this.addChild(this.labelMax);
        this.labelMax.anchor.set(0, -1);
        this.label.anchor.set(1, 0);
    }

    /**
     * @access public
     */
    resizeLabel() {
        super.resizeLabel();
        this.labelMax.x = this.bg.width - 10 - this.labelMax.width
        this.label.x = this.labelMax.x - this.labelMax.width + 55;
        // this.labelMax.x = this.label.x + this.label.width;
        this.labelMax.y = this.label.y -1 ; 
        const bgWidth = this.bg.width + this.labelMax.width;
        // this.drawBg(bgWidth);
        
    }


    /**
     * @access public
     * @param {number} seconds 
     */
    setScores(value){
        this.label.text = value +  "/";
        this.resizeLabel();
    }

}

export default ScoreInfo