import { AppConfig } from "../config/AppConfig";

class ResourceList {
}
ResourceList.LOGO = `./assets/logo.png`;
ResourceList.CART = `./assets/cart.png`;
ResourceList.CART_OVER_EN = `./assets/cart-over_en.png`;
ResourceList.CART_OVER_FR = `./assets/cart-over_fr.png`;

ResourceList.START_BUTTON_EN = `./assets/start-button_en.png`;
ResourceList.START_BUTTON_HOVER_EN = `./assets/start-button-hover_en.png`;
ResourceList.START_BUTTON_DOWN_EN = `./assets/start-button-down_en.png`;

ResourceList.START_BUTTON_FR = `./assets/start-button_fr.png`;
ResourceList.START_BUTTON_HOVER_FR = `./assets/start-button-hover_fr.png`;
ResourceList.START_BUTTON_DOWN_FR = `./assets/start-button-down_fr.png`;


ResourceList.CONVEYOR = `./assets/bg/conveyor.png`;
ResourceList.CONVEYOR_LIGHT_LEFT = `./assets/bg/conveyor-light_left.png`;
ResourceList.SPLASH_EN = `./assets/splash_en.png`;
ResourceList.SPLASH_FR = `./assets/splash_fr.png`;
ResourceList.SPLASH_LEFT = `./assets/splash-left.png`;
ResourceList.SPLASH_RIGHT = `./assets/splash-right.png`;
ResourceList.SPLASH_BOX = `./assets/splash-box.png`;
ResourceList.SPLASH_LOGO_EN = `./assets/splash-logo_en.png`;
ResourceList.SPLASH_LOGO_FR = `./assets/splash-logo_fr.png`;

ResourceList.ITEM_MAGNET = `./assets/items/item-magnet.png`;
ResourceList.ITEM_SPEEDUP = `./assets/items/item-speedup.png`;
ResourceList.ITEM_TIMEPLUS = `./assets/items/item-timeplus.png`;
ResourceList.ITEM_TIMEMINUS = `./assets/items/item-timeminus.png`;
ResourceList.ITEM_BAD = `./assets/items/item-bad.png?4218`;

ResourceList.TIMER_BASE = `./assets/timer-base.png`;
ResourceList.TIMER_PROGRESS_MIDDLE = `./assets/timer-progress-middle.png`;
ResourceList.TIMER_PROGRESS_RIGHT = `./assets/timer-progress-right.png`;
ResourceList.TIMER_PROGRESS_ARROW = `./assets/timer-arrow.png`;

ResourceList.PRGS_CAPSULE_BG = `./assets/progress/capsule-bg.png`;
ResourceList.PRGS_CAPSULE_FLARE = `./assets/progress/capsule-flare.png`;
ResourceList.PRGS_CAPSULE_SHADOW = `./assets/progress/capsule-shadow.png`;
ResourceList.PRGS_DISK_EXTRA_MAGNET = `./assets/progress/disk-extra-magnet.png`;
ResourceList.PRGS_DISK_EXTRA_SPEEDUP = `./assets/progress/disk-extra-speedup.png`;
ResourceList.PRGS_DISK_TIMER = `./assets/progress/disk-timer.png`;

ResourceList.MSC_INFOBOX_BG = `./assets/misc/infobox-bg.png`;
ResourceList.MSC_COIN = `./assets/misc/coin.png`;
ResourceList.MSC_COIN_BIG = `./assets/misc/coin-big.png`;
ResourceList.MSC_STAR = `./assets/misc/star.png`;
ResourceList.MSC_MINUS = `./assets/misc/minus.png`;
ResourceList.MSC_PLUS = `./assets/misc/plus.png`;
ResourceList.MSC_STAR4 = `./assets/misc/star4.png`;
ResourceList.MSC_DROP = `./assets/misc/drop.png`;
ResourceList.MSC_SHIMMER = `./assets/misc/shimmer.png`;
ResourceList.MSC_SPEEDLINE = `./assets/misc/speedline.png`;
ResourceList.MSC_FADE_CIRCLE = `./assets/misc/fade_circle.png`;
ResourceList.MSC_CIRCLE = `./assets/misc/circle.png`;
ResourceList.MSC_BTN_MUTE = `./assets/misc/btn_mute.png`;
ResourceList.MSC_BTN_UNMUTE = `./assets/misc/btn_unmute.png`;
ResourceList.MSC_BTN_CLOSE = `./assets/misc/btn_close.png`;
ResourceList.MSC_BTN_PLAY = `./assets/misc/btn_play.png`;
ResourceList.MSC_BTN_PAUSE = `./assets/misc/btn_pause.png`;
ResourceList.MSC_TOP_BANNER = `./assets/misc/top-banner.png`;
ResourceList.MSC_TOP_BANNER_AD_1 = `./assets/misc/top-banner-ad1.png`;
ResourceList.MSC_MIST = `./assets/misc/mist.png`;
ResourceList.MSC_SPEEDUP_EFFECT = `./assets/misc/speedup-effect.png`;
ResourceList.MSC_MAGNET_EFFECT = `./assets/misc/magnet-effect.png`;
ResourceList.MSC_SPEEDUP_CART_EFFECT = `./assets/misc/speedup-cart-effect.png`;
ResourceList.MSC_CART_CLICKER = `./assets/misc/cart-click.png`;
ResourceList.MSC_CART_CLICKER_LEFT_EN = `./assets/misc/cart-click-left_en.png`;
ResourceList.MSC_CART_CLICKER_CENTER_EN = `./assets/misc/cart-click-center_en.png`;
ResourceList.MSC_CART_CLICKER_RIGHT_EN = `./assets/misc/cart-click-right_en.png`;
ResourceList.MSC_CART_CLICKER_LEFT_FR = `./assets/misc/cart-click-left_fr.png`;
ResourceList.MSC_CART_CLICKER_CENTER_FR = `./assets/misc/cart-click-center_fr.png`;
ResourceList.MSC_CART_CLICKER_RIGHT_FR = `./assets/misc/cart-click-right_fr.png`;

ResourceList.MSC_BTN_REGLES_EN = `./assets/misc/btn-regles_en.png`;
ResourceList.MSC_BTN_REGLES_FR = `./assets/misc/btn-regles_fr.png`;

ResourceList.COUNTDOWN_1_EN = `./assets/countdown/count1_en.png`;
ResourceList.COUNTDOWN_1_FR = `./assets/countdown/count1_fr.png`;
ResourceList.COUNTDOWN_2_EN = `./assets/countdown/count2_en.png`;
ResourceList.COUNTDOWN_2_FR = `./assets/countdown/count2_fr.png`;
ResourceList.COUNTDOWN_3_EN = `./assets/countdown/count3_en.png`;
ResourceList.COUNTDOWN_3_FR = `./assets/countdown/count3_fr.png`;
ResourceList.COUNTDOWN_4_EN = `./assets/countdown/count4_en.png`;
ResourceList.COUNTDOWN_4_FR = `./assets/countdown/count4_fr.png`;
ResourceList.COUNTDOWN_GO_EN = `./assets/countdown/count-go_en.png`;
ResourceList.COUNTDOWN_GO_FR = `./assets/countdown/count-go_fr.png`;

ResourceList.PRD_1_ITEM_1 = `./assets/skins/products/products1/item-scale-0.png`;
ResourceList.PRD_1_ITEM_3 = `./assets/skins/products/products1/item-scale-2.png`;
ResourceList.PRD_1_ITEM_5 = `./assets/skins/products/products1/item-scale-4.png`;
ResourceList.PRD_1_ITEM_6 = `./assets/skins/products/products1/item-scale-5.png`;

ResourceList.PRD_2_ITEM_1 = `./assets/skins/products/products2/item-scale-0.png?4218`;
ResourceList.PRD_2_ITEM_2 = `./assets/skins/products/products2/item-scale-1.png?4218`;
ResourceList.PRD_2_ITEM_3 = `./assets/skins/products/products2/item-scale-2.png?4218`;
ResourceList.PRD_2_ITEM_4 = `./assets/skins/products/products2/item-scale-3.png?4218`;
ResourceList.PRD_2_ITEM_5 = `./assets/skins/products/products2/item-scale-4.png?4218`;
ResourceList.PRD_2_ITEM_6 = `./assets/skins/products/products2/item-scale-5.png?4218`;
ResourceList.PRD_2_ITEM_7 = `./assets/skins/products/products2/item-scale-6.png?4218`;
ResourceList.PRD_2_ITEM_8 = `./assets/skins/products/products2/item-scale-7.png?4218`;
ResourceList.PRD_2_ITEM_9 = `./assets/skins/products/products2/item-scale-8.png?4218`;
ResourceList.PRD_2_ITEM_10 = `./assets/skins/products/products2/item-scale-9.png?4218`;

ResourceList.PRD_3_ITEM_1 = `./assets/skins/products/products3/item-scale-0.png`;
ResourceList.PRD_3_ITEM_2 = `./assets/skins/products/products3/item-scale-1.png`;
ResourceList.PRD_3_ITEM_3 = `./assets/skins/products/products3/item-scale-2.png`;
ResourceList.PRD_3_ITEM_4 = `./assets/skins/products/products3/item-scale-3.png`;
ResourceList.PRD_3_ITEM_6 = `./assets/skins/products/products3/item-scale-5.png`;

ResourceList.PRD_4_ITEM_4 = `./assets/skins/products/products4/item-scale-3.png`;
ResourceList.PRD_4_ITEM_5 = `./assets/skins/products/products4/item-scale-4.png`;
ResourceList.PRD_4_ITEM_6 = `./assets/skins/products/products4/item-scale-5.png`;
ResourceList.PRD_4_ITEM_7 = `./assets/skins/products/products4/item-scale-6.png`;


ResourceList.BG_1_WALL = `./assets/skins/backs/game1/wall-tile.png`;
ResourceList.BG_1_FLOOR = `./assets/skins/backs/game1/floor-tile.png`;
ResourceList.BG_1_DOOR = `./assets/skins/backs/game1/door.png`;
ResourceList.BG_1_CHAIR_LEFT = `./assets/skins/backs/game1/chair-tile-left.png`;
ResourceList.BG_1_CHAIR_RIGHT = `./assets/skins/backs/game1/chair-tile-right.png`;
ResourceList.BG_1_CHAIRS_ROWS = `./assets/skins/backs/game1/charis-rows.png`;

ResourceList.BG_2_WALL = `./assets/skins/backs/game2/wall-tile.png`;
ResourceList.BG_2_FLOOR = `./assets/skins/backs/game2/floor-tile.png`;
ResourceList.BG_2_DOOR = `./assets/skins/backs/game2/door.png`;

ResourceList.BG_2_OBJ_LEFT_1 = `./assets/skins/backs/game2/object_left_1.png`;
ResourceList.BG_2_OBJ_RIGHT_1 = `./assets/skins/backs/game2/object_right_1.png`;

ResourceList.BG_3_WALL = `./assets/skins/backs/game3/wall-tile.png`;
ResourceList.BG_3_FLOOR = `./assets/skins/backs/game3/floor-tile.png`;
ResourceList.BG_3_DOOR = `./assets/skins/backs/game3/door.png`;
ResourceList.BG_3_OBJ_LEFT_1 = `./assets/skins/backs/game3/door.png`;

ResourceList.BG_3_OBJ_LEFT_1 = `./assets/skins/backs/game3/object_left_1.png`;
ResourceList.BG_3_OBJ_LEFT_2 = `./assets/skins/backs/game3/object_left_2.png`;
ResourceList.BG_3_OBJ_RIGHT_1 = `./assets/skins/backs/game3/object_right_1.png`;
ResourceList.BG_3_OBJ_RIGHT_2 = `./assets/skins/backs/game3/object_right_2.png`;
ResourceList.BG_3_OBJ_RIGHT_3 = `./assets/skins/backs/game3/object_right_3.png`;

ResourceList.BG_4_WALL = `./assets/skins/backs/game4/wall-tile.png`;
ResourceList.BG_4_FLOOR = `./assets/skins/backs/game4/floor-tile.png`;
ResourceList.BG_4_DOOR = `./assets/skins/backs/game4/door.png`;
ResourceList.BG_4_OBJ_LEFT_1 = `./assets/skins/backs/game4/object_left_1.png`;
ResourceList.BG_4_OBJ_RIGHT_1 = `./assets/skins/backs/game4/object_right_1.png`;

ResourceList.MSC_1_BANNER = `./assets/skins/logos/logo_1_cinepex.png`;
ResourceList.MSC_2_BANNER = `./assets/skins/logos/logo_2_cine.png`;
ResourceList.MSC_3_BANNER_EN = `./assets/skins/logos/logo_3_scotia_en.png`;
ResourceList.MSC_3_BANNER_FR = `./assets/skins/logos/logo_3_scotia_fr.png`;
// ResourceList.MSC_4_BANNER = `./assets/skins/logos/logo_4_scene.png`;
ResourceList.MSC_4_BANNER_EN = `./assets/skins/logos/logo_4_scene_en.png`;
ResourceList.MSC_4_BANNER_FR = `./assets/skins/logos/logo_4_scene_fr.png`;



ResourceList.PWUP_PRESENT = `./assets/products/present.png`;
ResourceList.PWUP_WATCH_MINUS = `./assets/products/watch-minus.png`;
ResourceList.PWUP_WATCH_PLUS = `./assets/products/watch-plus.png`;
ResourceList.PWUP_LIGHTING = `./assets/products/lighting.png`;

ResourceList.EFFECTS = `./assets/effects.json`;

ResourceList.MSC_BTN_REGLES = `./assets/misc/btn-regles.png`;

ResourceList.CUSTOM_RESOURCES = [];

ResourceList.LIST_GAME1 = [
    ResourceList.BG_1_WALL,
    ResourceList.BG_1_FLOOR,
    ResourceList.BG_1_DOOR,
    ResourceList.BG_1_CHAIR_LEFT,
    ResourceList.BG_1_CHAIR_RIGHT,
    ResourceList.BG_1_CHAIRS_ROWS,
    ResourceList.MSC_1_BANNER,  

    ResourceList.PRD_1_ITEM_1,  
    ResourceList.PRD_1_ITEM_3,  
    ResourceList.PRD_1_ITEM_5,
    ResourceList.PRD_1_ITEM_6
]

ResourceList.PRODUCTS_10_GAME1 = [
    ResourceList.PRD_1_ITEM_1,  
    ResourceList.PRD_1_ITEM_3,
];
ResourceList.PRODUCTS_20_GAME1 = [
    ResourceList.PRD_1_ITEM_5,
    ResourceList.PRD_1_ITEM_6
];

ResourceList.LIST_GAME2 = [
    ResourceList.BG_2_WALL,
    ResourceList.BG_2_FLOOR,
    ResourceList.BG_2_DOOR,
    ResourceList.MSC_2_BANNER,
    ResourceList.BG_2_OBJ_LEFT_1,
    ResourceList.BG_2_OBJ_RIGHT_1,

    ResourceList.PRD_2_ITEM_1, 
    ResourceList.PRD_2_ITEM_2, 
    ResourceList.PRD_2_ITEM_3, 
    ResourceList.PRD_2_ITEM_4,
    ResourceList.PRD_2_ITEM_5,
    ResourceList.PRD_2_ITEM_6,
    ResourceList.PRD_2_ITEM_7,
    ResourceList.PRD_2_ITEM_8,
    ResourceList.PRD_2_ITEM_9,
    ResourceList.PRD_2_ITEM_10
]

ResourceList.PRODUCTS_10_GAME2 = [
    ResourceList.PRD_2_ITEM_1, 
    ResourceList.PRD_2_ITEM_2, 
    ResourceList.PRD_2_ITEM_3, 
    ResourceList.PRD_2_ITEM_4,
    ResourceList.PRD_2_ITEM_5

];
ResourceList.PRODUCTS_20_GAME2 = [
    ResourceList.PRD_2_ITEM_6,
    ResourceList.PRD_2_ITEM_7,
    ResourceList.PRD_2_ITEM_8,
    ResourceList.PRD_2_ITEM_9,
    ResourceList.PRD_2_ITEM_10
];

ResourceList.LIST_GAME3 = [
    ResourceList.BG_3_WALL,
    ResourceList.BG_3_FLOOR,
    ResourceList.BG_3_DOOR,
    // ResourceList.MSC_3_BANNER,
    ResourceList.MSC_3_BANNER_EN,
    ResourceList.MSC_3_BANNER_FR,
    ResourceList.BG_3_OBJ_LEFT_1,
    ResourceList.BG_3_OBJ_LEFT_2,
    ResourceList.BG_3_OBJ_RIGHT_1,
    ResourceList.BG_3_OBJ_RIGHT_2,
    ResourceList.BG_3_OBJ_RIGHT_3,

    ResourceList.PRD_3_ITEM_1,
    ResourceList.PRD_3_ITEM_2,
    ResourceList.PRD_3_ITEM_3,
    ResourceList.PRD_3_ITEM_4,
    ResourceList.PRD_3_ITEM_6,
]

ResourceList.PRODUCTS_10_GAME3 = [
    ResourceList.PRD_3_ITEM_1,
    ResourceList.PRD_3_ITEM_2,
    ResourceList.PRD_3_ITEM_3,
];
ResourceList.PRODUCTS_20_GAME3 = [
    ResourceList.PRD_3_ITEM_4,
    ResourceList.PRD_3_ITEM_6,
];

ResourceList.LIST_GAME4 = [
    ResourceList.BG_4_WALL,
    ResourceList.BG_4_FLOOR,
    ResourceList.BG_4_DOOR,
    // ResourceList.MSC_4_BANNER,
    ResourceList.MSC_4_BANNER_EN,
    ResourceList.MSC_4_BANNER_FR,
    ResourceList.BG_4_OBJ_LEFT_1,
    ResourceList.BG_4_OBJ_RIGHT_1,

    ResourceList.PRD_4_ITEM_4,
    ResourceList.PRD_4_ITEM_5,
    ResourceList.PRD_4_ITEM_6,
    ResourceList.PRD_4_ITEM_7
]

ResourceList.PRODUCTS_10_GAME4 = [

    ResourceList.PRD_4_ITEM_4,
    ResourceList.PRD_4_ITEM_5,
    ResourceList.PRD_4_ITEM_6
];

ResourceList.PRODUCTS_20_GAME4 = [
    ResourceList.PRD_4_ITEM_7
];

ResourceList.LIST_LOCALE_EN = [
    ResourceList.MSC_BTN_REGLES_EN,
    ResourceList.START_BUTTON_EN,
    ResourceList.START_BUTTON_HOVER_EN,
    ResourceList.START_BUTTON_DOWN_EN,
    ResourceList.COUNTDOWN_GO_EN,
    ResourceList.COUNTDOWN_4_EN,
    ResourceList.COUNTDOWN_3_EN,
    ResourceList.COUNTDOWN_2_EN,
    ResourceList.COUNTDOWN_1_EN,
    ResourceList.SPLASH_LOGO_EN,
    ResourceList.CART_OVER_EN,
    ResourceList.MSC_CART_CLICKER_LEFT_EN,
    ResourceList.MSC_CART_CLICKER_CENTER_EN,
    ResourceList.MSC_CART_CLICKER_RIGHT_EN,
    ResourceList.SPLASH_EN
    
];

ResourceList.LIST_LOCALE_FR = [
    ResourceList.MSC_BTN_REGLES_FR,
    ResourceList.START_BUTTON_FR,
    ResourceList.START_BUTTON_HOVER_FR,
    ResourceList.START_BUTTON_DOWN_FR,
    ResourceList.COUNTDOWN_GO_FR,
    ResourceList.COUNTDOWN_4_FR,
    ResourceList.COUNTDOWN_3_FR,
    ResourceList.COUNTDOWN_2_FR,
    ResourceList.COUNTDOWN_1_FR,
    ResourceList.SPLASH_LOGO_FR,
    ResourceList.CART_OVER_FR,
    ResourceList.MSC_CART_CLICKER_LEFT_FR,
    ResourceList.MSC_CART_CLICKER_CENTER_FR,
    ResourceList.MSC_CART_CLICKER_RIGHT_FR,
    ResourceList.SPLASH_FR
];

ResourceList.LIST = [
    ResourceList.CART,
    // ResourceList.CART_OVER,
    // ResourceList.SPLASH,
    ResourceList.SPLASH_LEFT,
    ResourceList.SPLASH_RIGHT,
    ResourceList.SPLASH_BOX,
    // ResourceList.SPLASH_LOGO,
    ResourceList.ITEM_BAD,

    ResourceList.CONVEYOR,
    ResourceList.CONVEYOR_LIGHT_LEFT,

    ResourceList.ITEM_MAGNET,
    ResourceList.ITEM_SPEEDUP,
    ResourceList.ITEM_TIMEPLUS,
    ResourceList.ITEM_TIMEMINUS,
    // ResourceList.EFFECTS,
    ResourceList.TIMER_BASE,
    ResourceList.TIMER_PROGRESS_MIDDLE,
    ResourceList.TIMER_PROGRESS_RIGHT,
    ResourceList.TIMER_PROGRESS_ARROW,

    ResourceList.PRGS_CAPSULE_BG,
    ResourceList.PRGS_CAPSULE_FLARE,
    ResourceList.PRGS_CAPSULE_SHADOW,
    ResourceList.PRGS_DISK_EXTRA_MAGNET,
    ResourceList.PRGS_DISK_EXTRA_SPEEDUP,
    ResourceList.PRGS_DISK_TIMER,

    ResourceList.MSC_INFOBOX_BG,
    ResourceList.MSC_COIN,
    ResourceList.MSC_COIN_BIG,
    ResourceList.MSC_STAR,
    ResourceList.MSC_BTN_MUTE,
    ResourceList.MSC_BTN_UNMUTE,
    ResourceList.MSC_BTN_CLOSE,
    ResourceList.MSC_BTN_PLAY,
    ResourceList.MSC_BTN_PAUSE,
    ResourceList.MSC_TOP_BANNER,
    ResourceList.MSC_TOP_BANNER_AD_1,
    ResourceList.MSC_MIST,
    ResourceList.MSC_SPEEDUP_EFFECT,
    ResourceList.MSC_MAGNET_EFFECT,
    ResourceList.MSC_SPEEDUP_CART_EFFECT,
    ResourceList.MSC_CART_CLICKER,
    // ResourceList.MSC_CART_CLICKER_LEFT,
    // ResourceList.MSC_CART_CLICKER_CENTER,
    // ResourceList.MSC_CART_CLICKER_RIGHT,
    // ResourceList.MSC_BTN_REGLES,

    // ResourceList.COUNTDOWN_1,
    // ResourceList.COUNTDOWN_2,
    // ResourceList.COUNTDOWN_3,
    // ResourceList.COUNTDOWN_4,
    // ResourceList.COUNTDOWN_GO,

    ...ResourceList.CUSTOM_RESOURCES,
];


const skinArray = ResourceList["LIST_GAME" + AppConfig.gameID];
ResourceList.LIST = ResourceList.LIST.concat(skinArray);
const lang = AppConfig.lang;
const engRes = ResourceList.LIST_LOCALE_EN;
const frRes = ResourceList.LIST_LOCALE_FR;
const langArray = ResourceList["LIST_LOCALE_" + AppConfig.lang];
ResourceList.LIST = ResourceList.LIST.concat(langArray);

ResourceList.PRODUCTS_10_LIST = ResourceList["PRODUCTS_10_GAME" + AppConfig.gameID];
ResourceList.PRODUCTS_20_LIST = ResourceList["PRODUCTS_20_GAME" + AppConfig.gameID];

export default ResourceList;
