import SpriteCommon from './SpriteCommon';
import Point3D from '../../../model/pseudo3ds/Point3D';
import { AppConfig } from '../../../config/AppConfig';
import Utils3D from '../../utils/Utils3D';

class Pseudo3DSprite extends SpriteCommon {
    constructor(gameScreen, resourceName) {
        super(resourceName);
        this.gameScreen = gameScreen;
        this.responcivePosOnConveyor = 1;
        this.listen3D = true;
        // public onUpdate : () => void;
        this.point3D = new Point3D(() => this.updatePosByPoint3D());
    }

    updatePosByPoint3D() {
        if (!this.listen3D)
            return;

        const { gameWidth, gameHeight } = AppConfig.settings;
        const { focalLength, scaleZoom, horyzontPos, itemsExtraScale, zDeep, zAppeared } = AppConfig.settings3D;

        const w = gameWidth;
        const h = gameHeight;
        const centrX = w / 2;


        const cameraOffsetX = 0;

        // this.x = centrX + (this.point3D.x - cameraOffsetX) / (this.point3D.z + focalLength);
        this.x = Utils3D.getXByPoint3D(this.point3D);
        // this.y = h * horyzontPos + this.point3D.y / (this.point3D.z + focalLength);
        this.y = Utils3D.getYByPoint3D(this.point3D);
        const sc = itemsExtraScale * scaleZoom * 1 / (this.point3D.z + focalLength);
        this.alpha = this.calculateAlphaByZ(this.point3D.z);


        this.scale.set(sc, sc);

        if (this.point3D.z < AppConfig.settings3D.zPseudo3DBound && this.outOfBoundsCallback) {
            this.dispatchOutOfBounds();
        }
    }

    dispatchOutOfBounds() {
        if (this.outOfBoundsCallback)
            this.outOfBoundsCallback(this);
    }

    /**
     * 
     * @param {number} z //from zDeep to zAppeared (from 100 to 80 for example)
     */
    calculateAlphaByZ(z) {
        const { zDeep, zAppeared } = AppConfig.settings3D;

        if (z > zDeep) {
            return 0;
        }
        
        if (z <= zAppeared) {
            return 1;
        }
        const a = 1 - (z - zAppeared) / (zDeep - zAppeared);
        return a;


    }
}
export default Pseudo3DSprite;
