import { AppConfig } from "../../config/AppConfig";
import GameModel, { EGameStates } from "../../model/GameModel";
import EMessages from "../../services/EMessages";
import ResourceList from "../../services/ResourceList";
import SpriteCommon from "../components/common/SpriteCommon";
import gsap from 'gsap';
import PopupAbstract from "./PopupAbstract";
import AnalyticsService from "../../services/AnalyticsService";
import AnalyticsEvents from "../../services/AnalyticsEvents";

class PausePopUp extends PopupAbstract{
    /**
     * 
     * @param {GameModel} gameModel 
     */
    constructor(gameModel, finishWithPrizePopUp) {
        super(gameModel);
        this.finishWithPrizePopUp = finishWithPrizePopUp;
        this.setLinks('pause-game-');

        if (this.domLink && this.keepPlayingBtnDOM) {
            this.keepPlayingBtnDOM.cursor = "pointer";

            this.keepPlayingBtnDOM.addEventListener('pointerup', () => {
                // this.gameModel.registerPlayAgainInteraction();
                this.visible = false;
                AnalyticsService.logEvent(AnalyticsEvents.POPUP_1000POINTS_KEEP_PLAYING);

                if (this.gameModel.gameState == EGameStates.pause){
                    this.gameModel.resumeGame();
                }
            });    
        }

        if (this.domLink && this.getPrizeBtnDOM) {
            this.getPrizeBtnDOM.cursor = "pointer";

            this.getPrizeBtnDOM.addEventListener('pointerup', () => {
                // this.gameModel.registerPlayAgainInteraction();
                this.visible = false;
                AnalyticsService.logEvent(AnalyticsEvents.POPUP_1000POINTS_CLAIM_PRIZE);
                this.finishWithPrizePopUp.visible = true;
            });    
        }

        this.gameModel.gameStateUpdated.add(() => {
            if (this.gameModel.gameState === EGameStates.stop){
                if (this.gameModel.scoresAchived) {
                    // this.visible = true;
                } 
            } else if (this.gameModel.gameState === EGameStates.playing){
                this.visible = false;
            }
            
        });
        this.gameModel.goalScoresAchived.add(() => {
            this.visible = true;
        });
    }

    setLinks(domID) {
        super.setLinks(domID);
        const lang = AppConfig.lang.toLowerCase();
        this.getPrizeBtnDOM = document.querySelector(`#${domID}${lang} .get-prize-btn`);
        this.keepPlayingBtnDOM = document.querySelector(`#${domID}${lang} .keep-playing-btn`);
    }

 

}

export default PausePopUp